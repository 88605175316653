import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/charts";
import { useFetch } from "hooks/useFetch"; // Ensure the hook is correctly imported
import queryKeys from "common/queryKey"; // Ensure the query keys are correctly imported
import moment from "moment";
import { getClientid } from "redux/selectors";
import { connect } from "react-redux";
import { Spin } from "antd";
import DetailsModal from "organisms/DetailsModal";

const validQualifications = [
  "Bachelor",
  "Diploma",
  "Certificate",
  "Master",
  "Doctorate",
];

function QualificationByGender({ value, clientid }) {
  const initialData = [
    { Gender: "Male", Qualification: "Bachelor", Count: 0 },
    { Gender: "Female", Qualification: "Bachelor", Count: 0 },
    { Gender: "They/ Them", Qualification: "Bachelor", Count: 0 },
    { Gender: "Male", Qualification: "Diploma", Count: 0 },
    { Gender: "Female", Qualification: "Diploma", Count: 0 },
    { Gender: "They/ Them", Qualification: "Diploma", Count: 0 },
    { Gender: "Male", Qualification: "Certificate", Count: 0 },
    { Gender: "Female", Qualification: "Certificate", Count: 0 },
    { Gender: "They/ Them", Qualification: "Certificate", Count: 0 },
    { Gender: "Male", Qualification: "Master", Count: 0 },
    { Gender: "Female", Qualification: "Master", Count: 0 },
    { Gender: "They/ Them", Qualification: "Master", Count: 0 },
    { Gender: "Male", Qualification: "Doctorate", Count: 0 },
    { Gender: "Female", Qualification: "Doctorate", Count: 0 },
    { Gender: "They/ Them", Qualification: "Doctorate", Count: 0 },
  ];

  const [qualificationByGenderData, setQualificationByGenderData] = useState(initialData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [plotData, setPlotData] = useState("");


  const { getQualificationByGenderData } = queryKeys;

  const payload = {
    method: "qualificationByGender",
    parameters: {
      startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || "",
    },
  };

  const payloadString = JSON.stringify(payload);
  const { key, url } = getQualificationByGenderData(clientid, payloadString);
  const { data, isLoading } = useFetch(key, url, { enabled: !!clientid }, payload);

  useEffect(() => {
    if (data?.response?.qualificationByGender) {
      const transformedData = [...initialData];

      data.response.qualificationByGender.forEach((user) => {
        const gender = user.gender === "male" ? "Male" : user.gender === "female" ? "Female" : "They / Them";
        if (user?.educationdetails?.length > 0) {
          user.educationdetails.forEach((education) => {
            const qualification = education.awarded;
            if (validQualifications.includes(qualification)) {
              const dataItem = transformedData.find(
                (item) =>
                  item.Gender === gender &&
                  item.Qualification === qualification
              );
              if (dataItem) {
                dataItem.Count += 1;
              }
            }
          });
        }
      });

      setQualificationByGenderData(transformedData);
    }
  }, [data]);

  const plotClick = () =>{
    if(plotData?.data?.data){
      const filterData = data?.response?.qualificationByGender?.filter(
        (item) => {
          return (
            item.educationdetails.some(
              (ed) => ed.awarded === plotData.data.data.Qualification
            ) &&
            ((plotData.data.data.Gender === "Male" && item.gender === "male") ||
              (plotData.data.data.Gender === "Female" && item.gender === "female") ||
              (plotData.data.data.Gender === "They / Them" && item.gender === "They/ Them"))
          );
        }
      );

      setTableData(filterData);
      setIsModalVisible(true);
    }
}

useEffect(()=>{
if(plotData !== ""){
plotClick();
}
},[plotData])

  const config = {
    data: qualificationByGenderData,
    isGroup: true,
    xField: "Qualification",
    yField: "Count",
    seriesField: "Gender",
    color: ["#9CD3FA", "#FAC2F8", "#A39BFF"],
    label: {
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    animation: false,
    onReady: (plot) => {
      plot.on("plot:click", (evt) => {
        setPlotData(evt);
      });
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
      },
    },
    yAxis: {
      title: {
        text: "Head Count",
        style: {
          fontSize: 12,
          fontWeight: 500,
        },
      },
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };

  return (
    <>
      <h6 className="mt-2">Qualification By Gender:</h6>
      <Spin spinning={isLoading}>
        <div className="box-container">
          <Column {...config} />
        </div>
      </Spin>
      <DetailsModal
        isopen={isModalVisible}
        isCancel={() => setIsModalVisible(false)}
        tabledata={tableData}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(QualificationByGender);
