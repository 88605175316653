import React, { useState } from "react";
import { Column } from "@ant-design/plots";
import styled from "@emotion/styled";
import { Spin } from "antd";
import { connect } from "react-redux";
import { getAllDepartmentList } from "redux/selectors";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function ProductivityBasicSalaryInfo({data}) {
  const [displayReportLoader, toggleReportLoader] = useState(false);

  const reportData = data.flatMap((obj) => [
    { type: obj?.department_name, info: "Staff", count: obj?.usercount },
    { type: obj?.department_name, info: "Salary", count: obj?.salarycount },
  ]);


  const config = {
    data: reportData,
    isStack: true,
    xField: "type",
    yField: "count",
    seriesField: "info",
    height: 500,
    label: {
      position: "",
      content: "",
    },
    legend: {
      layout: "horizontal",
      position: "top",
    },
    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
    yAxis: {
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
      label: {
        formatter: (value) => value.slice(0, 3).toUpperCase(),
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
    },
    xAxis: {
      label: {
        formatter: (value) => value.slice(0, 3).toUpperCase(),
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
    },
  };

  return (
    <>
      <StyledTitle>Basic Salary: Total Staff to Cost Ratio</StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayReportLoader}>
          <Column {...config} />
        </Spin>
      </StyledChartContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  allDepartmentList: getAllDepartmentList(state),
});

export default connect(mapStateToProps)(ProductivityBasicSalaryInfo);
