import { filter, groupBy, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import { useFetch } from 'hooks/useFetch';
import queryKeys from 'common/queryKey';
import { connect } from 'react-redux';
import moment from 'moment';
import { getClientid, getLocations, getlevelList } from 'redux/selectors';
import { Spin } from 'antd';
import DetailsModal from 'organisms/DetailsModal';

const EmployeesPerLocation = ({ value, clientid, locationList, levelList }) => {
  console.log('locationList--->',locationList)
  const [employeePerLocationData, setEmployeePerLocationData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [levelData, setLevelData] = useState(getLevelData())

  function getLevelData (){
    let level5_values = [];
      levelList.forEach((level) => {
         if (level.levelName === "Level1") {
          level.value.forEach((value) => {
            level5_values.push(...value.value);
          });
        }
      });
    return level5_values
  }

  const { getEmployeePerLocationData } = queryKeys;

  const payload = {
    method: 'employeesPerLocation',
    parameters: {
      startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || ""
    }
  };

  const payloadString = JSON.stringify(payload);
  const { key, url } = getEmployeePerLocationData(clientid, payloadString);
  const { data, isLoading, refetch } = useFetch(key, url, { enabled: !!clientid }, payload);

  useEffect(() => {
    const dataList = data?.response?.employeesPerLocation;

    if (dataList) {

      const transformedData = map(levelData, (level) => {
        const users = filter(dataList, val => val.managingoffice.includes(level));
        return {
          baselocationid: level,
          locationName: level,
          employeeCount: users?.length, 
        };
      });
      // const transformedData = dataList?.map((value) => {
      //   const location = locationList.find((item) => item.location_id === value?.baselocationid);
      //   const count = dataList.filter((item)=>item?.baselocationid === value?.baselocationid)
      //   return {
      //     baselocationid: value?.baselocationid,
      //     locationName: location?.country,
      //     employeeCount: count?.length, 
      //   };
      // });

      setEmployeePerLocationData(transformedData);
    }
  }, [data, locationList]);
  

  const config = {
    data: employeePerLocationData,
    xField: 'locationName',
    yField: 'employeeCount',
    color: '#9CD3FA',
    animation: false,
    onReady: (plot) => {
      plot.on('plot:click', (evt) => {
        if (evt.data.data.locationName) {
          const tabledata = data?.response?.employeesPerLocation?.filter((item)=>item?.managingoffice.includes(evt.data.data.locationName))
          setModalContent(tabledata);
          setIsModalVisible(true);
        }
      });
    },
    label: {
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' }
      ]
    },
    xAxis: {
      title: {
        text: 'Country',
        style: {
          fontSize: 12,
          fontWeight: 500
        }
      },
      label: {
        fontSize: 12,
        autoHide: true,
        autoRotate: true,
      },
    },
    yAxis: {
      title: {
        text: 'Head Count',
        style: {
          fontSize: 12,
          fontWeight: 500
        }
      },
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      }
    }
    
  };

  return (
    <>
      <h6 className='mt-2'>Number of employees per Country:</h6>
      <div className='box-container'>
        <Spin spinning={isLoading}>
          <Column {...config} />
        </Spin>
      </div>
      <DetailsModal
        isopen={isModalVisible}
        isCancel={()=>setIsModalVisible(false)}
        tabledata={modalContent}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  clientid: getClientid(state),
  locationList: getLocations(state),
  levelList: getlevelList(state),
});

export default connect(mapStateToProps, null)(EmployeesPerLocation);
