import { Dropdown, Upload, message, Menu, Input } from 'antd';
import Table from 'atoms/Table'
import { CALL_API } from 'common/API';
import { STATUS_CODE } from 'common/Constants';
import React, { useEffect, useRef, useState } from 'react'
import { ButtonFilled } from 'reusableComponent/UIButtons';
import download from "downloadjs";
import styled from 'styled-components';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleProcessingModal, toggleSuccessModal } from 'redux/actions';
// import { getMenuList } from 'redux/selectors';
import moment from 'moment';
import { SearchOutlined } from '@mui/icons-material';

const StyledViewMenu = styled.div`
  width: 150px;
  cursor: pointer;
`;
const StyledDropdownMenu = styled(Dropdown)`
  width: 150px;
  cursor: pointer;
`;


function DocumentTableView({ IdentityInfoColumns, dataSource, goBack, selectedItem, fetchMasterData, filteruser, clientId, updateProcessingModal }) {
  const [isDocumentUpload, setIsDocumentupload] = useState(false);
  const [fileDetails, setFileDetails] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const containerRef = useRef(null);
  const backStyle = { cursor: "pointer" }
  const otherColumn = [
    {
      title: "File Name",
      dataIndex: "documentName",
      key: "documentName",
    },
    {
      title: "Date added",
      dataIndex: "createdon",
      key: "createdon",
      render: (date) => {
        const dateFormat = moment(date).format("DD-MM-YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Size",
      dataIndex: "fileSize",
      key: "fileSize",
      render: (size) => {
        return `${size} kb`;
      },

    },
    {
      title: "",
      dataIndex: "document",
      key: "document",
      render: (_, record) => (
        <div ref={containerRef}>
          <StyledDropdownMenu
            getPopupContainer={() => containerRef.current}
            overlay={
              <StyledViewMenu>
                <Menu >
                  {/* {MenuList?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "recruitment" && subItem?.permission.edit)) && ( */}
                  <Menu.Item
                    key="2"
                    onClick={() => {
                      handleMenuClick(
                        record?.documentId,
                        selectedItem?.value,
                      )
                    }}
                  >
                    Download
                  </Menu.Item>
                  {/* )} */}
                  {/* {MenuList?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "recruitment" && subItem?.permission.delete)) && ( */}
                  <Menu.Item
                    key="4"
                    onClick={() => {
                      deleteDocument(record?.documentId)
                    }}
                  >
                    Delete
                  </Menu.Item>
                  {/* )} */}
                </Menu>
              </StyledViewMenu>
            }
            trigger={["click"]}
            placement="bottomRight"
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {/* {MenuList?.find(item => item?.key === "HRResources" && item?.subMenu?.find(subItem => subItem?.key === "recruitment" && (subItem?.permission.edit || subItem?.permission.delete))) && ( */}
              <MoreHorizIcon />
              {/* )} */}
            </span>
          </StyledDropdownMenu>
        </div>
      ),
    },
  ];

  const handleupload = async () => {
    updateProcessingModal(true);
    const { code } = await CALL_API(
      `upload-identity/${clientId}/${filteruser?.userid}`,
      "post",
      {
        name: fileDetails.name,
        type: fileDetails.type,
        content: fileDetails?.content,
        documentName: fileDetails?.documentName,
        fileSize: fileDetails?.fileSize
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      message.success("Document uploaded");
      fetchMasterData();
    } else {
      message.error(
        "You can only upload files up to 10MB in size."
      );
    }
    setFileDetails("")
    updateProcessingModal(false);
    setIsDocumentupload(false);
  }

  useEffect(() => {
    if (fileDetails !== "") {
      handleupload()
    }
  }, [fileDetails])

  const handleMenuClick = async (id, type) => {
    // setPrreviewType();
    updateProcessingModal(true);
    const { code, url } = await CALL_API(`document-list/${clientId}`, "post", {
      method: "preview-identity-document",
      documentId: id,
      identityName: type,
      userId: filteruser?.userid,
    });
    if (code === STATUS_CODE.SUCCESS) {
      download(url);
    } else {
      message.error("Something went wrong!! Please try again.");
    }
    updateProcessingModal(false);
  };

  const deleteDocument = async (id) => {
    updateProcessingModal(true);
    const { code } = await CALL_API(`upload-document/${clientId}/${id}`, 'delete', {
      documentName: selectedItem.value,
      userId: filteruser?.userid
    });
    if (code === STATUS_CODE.SUCCESS) {
      message.success("Document has been Deleted")
      fetchMasterData();
    }
    updateProcessingModal(false);
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredDataSource = (filteruser[selectedItem.value] ? filteruser[selectedItem.value] : [])?.filter((item) =>
    item.documentName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const searchStyle = { margin: '10px 0px', maxWidth: '400px' }

  return (
    <div>
      <h6><u style={backStyle} onClick={goBack}>Back</u>  {">"} {selectedItem.label}</h6>
      <div className='w-100 d-flex justify-content-between'>
        {selectedItem.label !== "Identity Information" && (
          <Input
            placeholder="Search"
            onChange={handleSearchChange}
            suffix={<SearchOutlined />}
            style={searchStyle}
          />
        )}
        <div className='text-end'>
          {selectedItem.label !== "Identity Information" && (
            <Upload
              showUploadList={false}
              customRequest={({ file, onSuccess }) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = () => {
                  const base64Data = reader.result.split(",")[1];
                  onSuccess();
                  setFileDetails({
                    name: file.name,
                    type: file.type,
                    content: base64Data,
                    fileSize: file.size,
                    documentName: selectedItem.value

                  })
                };

                reader.onerror = (error) => {
                  console.error("File read error:", error);
                };
              }}
              onChange={(info) => {
                if (info.file.status === "done") {
                  // handleFileUpload(info)
                  if (info.fileList.length > 0) {
                    const file = info.fileList[0].originFileObj;

                    const reader = new FileReader();

                    reader.onload = async (event) => {
                      const base64Data = event.target.result;
                      setIsDocumentupload(false);

                      // Log the information
                    };

                    reader.readAsDataURL(file);
                  }
                } else if (info.file.status === "error") {
                }
              }}
            >
              <ButtonFilled loading={isDocumentUpload}>Add New</ButtonFilled>
            </Upload>

          )}
        </div>
      </div>
      <Table
        className="mb-5"
        dataSource={selectedItem.label === "Identity Information" ? dataSource : filteredDataSource}
        columns={selectedItem.label === "Identity Information" ? IdentityInfoColumns : otherColumn}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "" : "non-white"
        }
        rowKey="role_id"
      />

    </div>
  )
}

const mapStateToProps = (state) => ({
  // menuList: getMenuList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
      updateSuccessModal: toggleSuccessModal,
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(DocumentTableView);


// export default DocumentTableView