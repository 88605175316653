import React, { useEffect, useMemo, useState } from 'react'
import { Column } from '@ant-design/plots';
import queryKeys from 'common/queryKey';
import moment from 'moment';
import { connect } from 'react-redux';
import { getClientid } from 'redux/selectors';
import { useFetch } from 'hooks/useFetch';
import { forEach, groupBy } from 'lodash';
import { Spin } from 'antd';
import DetailsModal from 'organisms/DetailsModal';

// Initial data to be updated with new ranges
const initialData = [
    { "name": "0-5", "xValue": "Bachelor", "yValue": 0, "userList": [] },
    { "name": "5-10", "xValue": "Bachelor", "yValue": 0, "userList": []  },
    { "name": "10-15", "xValue": "Bachelor", "yValue": 0, "userList": [] },
    { "name": "15+", "xValue": "Bachelor", "yValue": 0, "userList": [] },
    { "name": "0-5", "xValue": "Diploma", "yValue": 0, "userList": [] },
    { "name": "5-10", "xValue": "Diploma", "yValue": 0, "userList": [] },
    { "name": "10-15", "xValue": "Diploma", "yValue": 0, "userList": [] },
    { "name": "15+", "xValue": "Diploma", "yValue": 0, "userList": [] },
    { "name": "0-5", "xValue": "Certificate", "yValue": 0, "userList": [] },
    { "name": "5-10", "xValue": "Certificate", "yValue": 0, "userList": [] },
    { "name": "10-15", "xValue": "Certificate", "yValue": 0, "userList": [] },
    { "name": "15+", "xValue": "Certificate", "yValue": 0, "userList": [] },
    { "name": "0-5", "xValue": "Master", "yValue": 0, "userList": [] },
    { "name": "5-10", "xValue": "Master", "yValue": 0, "userList": [] },
    { "name": "10-15", "xValue": "Master", "yValue": 0, "userList": [] },
    { "name": "15+", "xValue": "Master", "yValue": 0, "userList": [] },
    { "name": "0-5", "xValue": "Doctorate", "yValue": 0, "userList": [] },
    { "name": "5-10", "xValue": "Doctorate", "yValue": 0, "userList": [] },
    { "name": "10-15", "xValue": "Doctorate", "yValue": 0, "userList": [] },
    { "name": "15+", "xValue": "Doctorate", "yValue": 0, "userList": [] }
];

function QualificationByTenure({ value, clientid }) {

    const [dataList, setDataList] = useState(initialData);
    const [employeesTotalExperienceData, setEmployeesTotalExperienceData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [plotData, setPlotData] = useState("");


    const { getQualificationByTenure, getEmployeesTotalExperienceData } = queryKeys;

    const payload = {
        method: "qualificationByTenure",
        parameters: {
            startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
            endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
            managingOffice: value?.managingoffice || [],
            competencyQC: value?.competencyQC || [],
            officesupervisor: value?.officesupervisor || ""
          }
    };

    const payloadEx = {
        method: "employeesTotalExperience",
        parameters: {
            startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
            endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
            managingOffice: value?.managingoffice || [],
            organisationLevel: value?.organisationlevel || [],
            competencyQC: value?.competencyQC || [],
            supervisor: value?.supervisor || "",
            officesupervisor: value?.manager || ""
          }
    };

    const payloadString = JSON.stringify(payload);
    const payloadStringEx = JSON.stringify(payloadEx);

    const { key: experienceKey, url: experienceUrl } = getEmployeesTotalExperienceData(clientid, payloadStringEx);
    const { data: experienceData, isLoading: loading } = useFetch(
        experienceKey,
        experienceUrl,
        {
            enabled: !!clientid,
        },
        payloadEx
    );

    useEffect(() => {
        const dataList = experienceData?.response?.employeesTotalExperience;
        if (dataList) {
            const calculateTotalExperience = (data) => {
                return data.map(user => {
                    let totalExperience = user.currentCompanyExperience || 0;
                    if(user?.previousCompanyWorkExperience?.length > 0){
                        user.previousCompanyWorkExperience.forEach(experience => {
                            const fromDate = new Date(experience.fromdate);
                            const toDate = new Date(experience.todate);
                            const duration = (toDate - fromDate) / (1000 * 60 * 60 * 24 * 365); // convert milliseconds to years
                            totalExperience += duration;
                        });
                    }
                    return {
                        userId: user.userId,
                        totalExperience: Math.floor(totalExperience) // rounded to the nearest whole number
                    };
                });
            };

            const experienceData = calculateTotalExperience(dataList);
            setEmployeesTotalExperienceData(experienceData);
        }
    }, [experienceData]);





    const { key, url } = getQualificationByTenure(clientid, payloadString);

    const { data, isLoading, refetch } = useFetch(
        key,
        url,
        {
            enabled: !!clientid,
        },
        payload
    );

    useEffect(() => {
        const fetchData = async () => {
            const response = data?.response?.qualificationByTenure;
            if (response) {
                const updatedData = await updateInitialData(employeesTotalExperienceData, response);
                setDataList(updatedData)
            }
        };
    
        fetchData();
    }, [data, employeesTotalExperienceData]);

    const updateInitialData = async (users, educationDetails) => {
        
        // Group users by their totalExperience
        const dataCopy = JSON.parse(JSON.stringify(initialData));

        const groupedUsers = groupBy(users, 'totalExperience');

        // Loop through each experience group and update the initialData
        forEach(groupedUsers, (group, experience) => {
            experience = parseInt(experience);
            group.forEach(user => {
                // Retrieve user's education details
                const userEducationDetail = educationDetails?.find(detail => detail?.userid === user?.userId);
                if (userEducationDetail && userEducationDetail?.educationdetails?.length > 0) {
                    userEducationDetail.educationdetails.forEach(detail => {
                        // Find matching entry in initialData
                        dataCopy.forEach(data => {
                            if ((experience >= 0 && experience < 5 && data.name === "0-5") ||
                                (experience >= 5 && experience < 10 && data.name === "5-10") ||
                                (experience >= 10 && experience < 15 && data.name === "10-15") ||
                                (experience >= 15 && data.name === "15+")) {
                                if (data.xValue === detail.awarded) {
                                    data.yValue++;
                                    data.userList.push(user)
                                }
                            }
                        });
                    });
                }
            });
        });


       return dataCopy

    };

    const plotClick = () => {
      if (plotData?.data?.data) {
          // Extracting userIds from plotData
          const userIds = plotData?.data?.data.userList.map((val) => val?.userId);
          // Filtering data based on userIds
          const filterData = data?.response?.qualificationByTenure?.filter((item) => {
              return userIds.includes(item?.userid);
          });
  
          setTableData(filterData);
          setIsModalVisible(true);
      }
  };
  
  

useEffect(()=>{
  if(plotData !== ""){
  plotClick();
  }
},[plotData])

    const config = useMemo(() => ({
        data: dataList,
        isGroup: true, // Enable grouped columns
        xField: 'xValue',
        yField: 'yValue',
        seriesField: 'name', // Group by the 'name' field
        colorField: 'name',
        animation: false,
        groupField: 'name', // Group by the 'name' field
        color: ['#F26B6B', '#FAC49C', '#9CD3FA', '#C2FAE5', '#FAC2F8'],
        onReady: (plot) => {
          plot.on("plot:click", (evt) => {
            setPlotData(evt);
          });
        },
        label: {
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' }, 
                { type: 'adjust-color' } 
            ],
        },
        xAxis: {
            label: {
                // formatter: (value) => value.slice(0, 3),
                // offset: 10,
                autoHide: true,
                autoRotate: true,
            },
        },
        yAxis: {
            tickInterval: 4,
            title: {
                text: 'Head Count',
                style: {
                    fontSize: 12,
                    fontWeight: 500
                },
            },
            grid: {
                line: {
                    style: {
                        opacity: 0
                    }
                }
            }
        },
    }), [dataList]);


    return (<>
    <h6 className='mt-2'>Qualification By Tenure:</h6>
    <div className='box-container'>
            <Spin spinning={isLoading}>
              <Column {...config} />
            </Spin>
        </div>
        <DetailsModal
        isopen={isModalVisible}
        isCancel={() => setIsModalVisible(false)}
        tabledata={tableData}
      />
    </>
        
    )
}

const mapStateToProps = (state) => ({
    clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(QualificationByTenure);

