import React from "react";
import { Line } from "@ant-design/plots";
import styled from "@emotion/styled";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function YearwiseHeadCount({ report }) {
  const config = {
    data: report,
    xField: "Year",
    yField: "count",
    seriesField: "type",
    height: 300,
    label: false,
    point: {
      size: 5,
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
        },
      },
    },
    yAxis: {
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };

  return (
    <>
      <StyledTitle>Headcount Evolution</StyledTitle>
      <StyledChartContainer>
        <Line {...config} />
      </StyledChartContainer>
    </>
  );
}

export default YearwiseHeadCount;
