import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import AttritionbyAge from './AttritionbyAge'
import AttritionByGradeCode from './AttritionByGradeCode'
import AttritionbyManager from './AttritionbyManager'
import AttritionbyDepartment from './AttritionbyDepartment'
import AttritionbyPerformance from './AttritionbyPerformance'
import Filters from 'molecules/Filters'
import { StyledDiv } from 'pages/PeopleDashboard/PeopleDashboard'
import moment from 'moment'
import { getClientid } from 'redux/selectors'
import { connect } from 'react-redux'
import queryKeys from 'common/queryKey'
import { useFetch } from 'hooks/useFetch'


function RetentionAndAttrition({clientid}) {
    const initialValues={
        competencyQC: [],
        serviceline: [],
        managingoffice: [],
        employee: "",
        manager: "",
        date: "",
        end_date: ""
      }
    
    const [value, setValue] = useState(initialValues);
    const [ commonData, setCommonData] = useState({})


const { getPeopleDashboardCommonCountData } = queryKeys

const payload =  {
  method: "commonCount",
  parameters: {
    startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
    endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
    managingOffice: value?.managingoffice || [],
    competencyQC: value?.competencyQC || [],
    officesupervisor: value?.officesupervisor || "",
    attritionstartDate: value?.date === "" ? moment().subtract(11, "months").startOf("month").format('YYYY-MM-DD') : moment(value.date).subtract(11, "months").startOf("month").format('YYYY-MM-DD'),
    attritionendDate: value?.date === "" ? moment().endOf("month").format('YYYY-MM-DD') : moment(value.date).endOf('month').format('YYYY-MM-DD'),
  }
}
const payloadString = JSON.stringify(payload);
const { key, url } = getPeopleDashboardCommonCountData(clientid, payloadString);
const {
  data,
  isLoading,
  refetch,
} = useFetch(
  key,
  url,
  {
    enabled: !!clientid,
  },
  payload
);

useEffect(() => {
  const dataList = data?.response
  if(dataList){
    setCommonData(dataList);
  }
}, [data]);
    return (
        <div>
            <StyledDiv>
                <b className='pb-2'><u>Retention & Attrition</u></b>
                <Filters setValue={setValue} value={value}/>
                <div className='mainbox'>
                    <div className='maincontent'>
        <span>{commonData?.headCount ? commonData?.headCount : 0}</span>
        <b>HeadCount</b>
      </div>
      <div className='maincontent'>
        <span>{commonData?.maleCount ? commonData?.maleCount : 0}</span>
        <b>Male</b>
      </div>
      <div className='maincontent'>
        <span>{commonData?.femaleCount ? commonData?.femaleCount : 0}</span>
        <b>Female</b>
      </div>
      <div className='maincontent'>
        <span>{commonData?.hireCount ? commonData?.hireCount : 0}</span>
        <b>Hiring Count</b>
      </div>
      <div className='maincontent'>
        <span>{commonData?.hireCount ? ((commonData?.hireCount / commonData?.headCount) * 100).toFixed(1) : "0"}%</span>
        <b>Hiring Rate</b>
      </div>
      <div className='maincontent'>
        <span>{commonData?.attritionCount ? commonData?.attritionCount : 0}</span>
        <b>Attrition Count</b>
      </div>
      <div className='maincontent'>
        <span>{commonData?.attritionCount ? ((commonData?.attritionCount / commonData?.headCount) * 100).toFixed(1) : "0"}%</span>
        <b>Attrition Rate</b>
      </div>
                </div>
            </StyledDiv>
            <Row gutter={16}>
                <Col md={24} sm={24} xs={24} xl={12}>
                    
                    <AttritionbyAge value={value}/>
                </Col>
                <Col md={24} sm={24} xs={24} xl={12}>
                    <AttritionByGradeCode value={value}/>
                </Col>

                <Col md={24} sm={24} xs={24} xl={12}>
                    <AttritionbyManager value={value}/>
                </Col>

                <Col md={24} sm={24} xs={24} xl={12}>
                   
                    <AttritionbyPerformance value={value}/>
                </Col>
                <Col md={24} sm={24} xs={24} xl={24}>
                    <AttritionbyDepartment value={value}/>
                </Col>
            </Row>
        </div>
    )
}
const mapStateToProps = state => ({
    clientid: getClientid(state),
  });
  
  export default connect(mapStateToProps,null)(RetentionAndAttrition)