import React, { useEffect, useState } from 'react';
import { Bar } from '@ant-design/plots';
import moment from 'moment';
import queryKeys from 'common/queryKey';
import { connect } from 'react-redux';
import { getClientid } from 'redux/selectors';
import { useFetch } from 'hooks/useFetch';
import { Spin } from 'antd';
import DetailsModal from 'organisms/DetailsModal';

const initialData = [
    { "name": "Male", "xValue": "20-30", "yValue": 0 },
    { "name": "Female", "xValue": "20-30", "yValue": 0 },
    { "name": "They / Them", "xValue": "20-30", "yValue": 0 },
    { "name": "Male", "xValue": "30-40", "yValue": 0 },
    { "name": "Female", "xValue": "30-40", "yValue": 0 },
    { "name": "They / Them", "xValue": "30-40", "yValue": 0 },
    { "name": "Male", "xValue": "40-50", "yValue": 0 },
    { "name": "Female", "xValue": "40-50", "yValue": 0 },
    { "name": "They / Them", "xValue": "40-50", "yValue": 0 },
];

function AttritionbyAge({ value, clientid }) {
    const [dataList, setDataList] = useState(initialData);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [plotData, setPlotData] = useState("");


    const { getAttritionbyAge } = queryKeys;

    const payload = {
        method: "attritionByAgeBand",
        parameters: {
            startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
            endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
            managingOffice: value?.managingoffice || [],
            competencyQC: value?.competencyQC || [],
            officesupervisor: value?.officesupervisor || ""
        }
    };

    const payloadString = JSON.stringify(payload);
    const { key, url } = getAttritionbyAge(clientid, payloadString);

    const { data, isLoading, refetch } = useFetch(
        key,
        url,
        {
            enabled: !!clientid,
        },
        payload
    );

    useEffect(() => {
        const response = data?.response?.attritionByAgeBand;
        if (response) {
            const updatedData = initialData.map(item => {
                const count = response.filter(u => u.range === item.xValue && 
                    (u.gender === "male" ? "Male" : 
                     u.gender === "female" ? "Female" : 
                     "They / Them") === item.name
                ).length;
                return { ...item, yValue: count };
            });
            setDataList(updatedData);
        }
    }, [data]);

    const plotClick = () =>{
        if (plotData?.data?.data) {
            const filterData = data?.response?.attritionByAgeBand?.filter(
                item => item.range === plotData.data.data.xValue && (
                    (plotData.data.data.name === "Male" && item.gender === "male") ||
                    (plotData.data.data.name === "Female" && item.gender === "female") ||
                    (plotData.data.data.name === "They / Them" && item.gender === "They/ Them")
                )
            );
            setTableData(filterData);
            setIsModalVisible(true);
            console.log('data--->',data?.response?.attritionByAgeBand)
        }
    }

    useEffect(()=>{
        if(plotData !== ""){
        plotClick();
        }
    },[plotData])

    const config = {
        data: dataList,
        isGroup: true,
        xField: 'yValue',
        yField: 'xValue',
        seriesField: 'name',
        colorField: 'name',
        groupField: 'name',
        color: ['#9CD3FA', '#FAC2F8', '#A39BFF'],
        onReady: (plot) => {
            plot.on('plot:click', (evt) => {
                setPlotData(evt);
            });
        }, 
        animation: false,
        label: {
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' }
            ],
        },
        xAxis: {
            tickInterval: 3,
            grid: {
                line: {
                    style: {
                        opacity: 0
                    }
                }
            }
        },
    };

    return (
        <>
            <h6 className="mt-2">Attrition by age band:</h6>
            <div className='box-container'>
                <Spin spinning={isLoading}>
                    <Bar {...config} />
                </Spin>
            </div>
            <DetailsModal
                isopen={isModalVisible}
                isCancel={() => setIsModalVisible(false)}
                tabledata={tableData}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(AttritionbyAge);
