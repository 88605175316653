import React, { useCallback } from "react";
import { Column } from "@ant-design/plots";
import styled from "@emotion/styled";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function DemographicsGenderDiversity({ report }) {
  const memoizedFormatter = useCallback((value) => {
    return value ? value.slice(0, 3).toUpperCase() : value;
  }, []);

  const config = {
    data: report,
    isStack: true,
    height: 300,
    xField: "department_name",
    yField: "count",
    seriesField: "sex",
    label: {
      position: "middle",
      content: "",
    },
    columnStyle: {
      cursor: "pointer",
    },
    legend: {
      layout: "horizontal",
      position: "top",
    },
    yAxis: {
      // max: isNaN(max(map(reportData, 'count'))) ? 100 : max(map(reportData, 'count')) + 25,
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
    xAxis: {
      label: {
        formatter: memoizedFormatter,
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
    },
  };
  return (
    <>
      <StyledTitle>Gender Diversity</StyledTitle>
      <StyledChartContainer>
        <Column {...config} />
      </StyledChartContainer>
    </>
  );
}

export default DemographicsGenderDiversity;
