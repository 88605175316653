import React, { useEffect, useState } from "react";
import { Pie } from "@ant-design/plots";
import queryKeys from "common/queryKey";
import moment from "moment";
import { useFetch } from "hooks/useFetch";
import { getClientid } from "redux/selectors";
import { connect } from "react-redux";
import { Spin, Modal } from "antd";
import DetailsModal from "organisms/DetailsModal";

function GenderDistribution({ value, clientid }) {

  const initialData = [
    { type: "Male", value: 0 },
    { type: "Female", value: 0 },
    { type: "They/ Them", value: 0 }
  ];

  const [genderDistributionData, setGenderDistributionData] = useState(initialData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState([]);


  const { getGenderDistributionData } = queryKeys;

  const payload = {
    method: "genderDistribution",
    parameters: {
      startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || ""
    }
  };
  const payloadString = JSON.stringify(payload);
  const { key, url } = getGenderDistributionData(clientid, payloadString);
  const { data, isLoading, refetch } = useFetch(key, url, {
    enabled: !!clientid,
  }, payload);

  useEffect(() => {
    const dataList = data?.response?.genderDistribution;
    if (dataList) {
      const genderCounts = dataList.reduce((acc, item) => {
        const gender = item.gender.toLowerCase();
        if (gender === "male") {
          acc.male += 1;
        } else if (gender === "female") {
          acc.female += 1;
        } else if (gender === "they/ them") {
          acc.they += 1;
        }
        return acc;
      }, { male: 0, female: 0, they: 0 });

      const transformedData = [
        { type: "Male", value: genderCounts.male },
        { type: "Female", value: genderCounts.female },
        { type: "They/ Them", value: genderCounts.they }
      ];
      setGenderDistributionData(transformedData);
    }
  }, [data]);


  const config = {
    appendPadding: 9,
    data: genderDistributionData,
    angleField: 'value',
    colorField: 'type',
    paddingRight: 80,
    innerRadius: 0.6,
    radius: 0.6,
    animation: false,
    label: {
      text: 'value',
      content: ({ type, value, percent }) => `${value} (${(percent * 100).toFixed(0)}%)`,
      style: {
        fontWeight: 'bold',
      },
    },
    legend: {
      position: 'right',
      title: {
        text: 'Employee Gender', // Title for the legends
        style: {
          fontSize: 14,
          fontWeight: 'bold',
          fill: 'black', // Customize title text color if needed
        },
      },
    },
    annotations: [
      {
        type: 'text',
        style: {
          text: '',
          x: '0%',
          y: '0%',
          textAlign: 'center',
          fontSize: 40,
          fontStyle: 'bold',
        },
      },
    ], 
    statistic: {
      title: {
        offsetY: -4,
        style: {
          fontWeight: 'bold',
        },
        customHtml: (container) => {
          const text = ``;
          return text;
        },
      },
      content: {
        offsetY: 4,
        style: {
          height: '20px',
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '14px',
          fontWeight: 'normal',
        },
        customHtml: (container) => {
          const text = '';
          return text;
        },
      },
    },
    color: ['#FAC2F8', '#B0DFFB', '#A39BFF'],
    onReady: (plot) => {
      plot.on('plot:click', (evt) => {
        if (evt.data.data.type === "Male") {
          const maledata = data?.response?.genderDistribution?.filter((item)=>item?.gender === "male")
          setModalContent(maledata);
          setIsModalVisible(true);
        }else if(evt.data.data.type === "Female"){
          const femaledata = data?.response?.genderDistribution?.filter((item)=>item?.gender === "female")
          setModalContent(femaledata);
          setIsModalVisible(true);
        }
        else if(evt.data.data.type === "They/ Them"){
          const theythemdata = data?.response?.genderDistribution?.filter((item)=>item?.gender === "They/ Them")
          setModalContent(theythemdata);
          setIsModalVisible(true);
        }
      });
    },
  };

  return (
    <>
      <h6 className="mt-2">Gender Distribution:</h6>
      <Spin spinning={isLoading}>
        <div className="box-container">
          <Pie {...config} />
        </div>
      </Spin>
      <DetailsModal
        isopen={isModalVisible}
        isCancel={()=>setIsModalVisible(false)}
        tabledata={modalContent}
      />
    </>
  );
}

const mapStateToProps = state => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(GenderDistribution);
