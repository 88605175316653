import React, { useCallback } from "react";
import { Column } from "@ant-design/plots";
import styled from "@emotion/styled";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function DemographicsHeadcountSector({ report }) {
  const memoizedFormatter = useCallback((value) => {
    return value ? value.slice(0, 3) : value;
  }, []);

  const config = {
    padding: "auto",
    data: report,
    height: 300,
    xField: "department_name",
    yField: "noOfemployee",
    seriesField: "department_name",
    label: {
      position: "middle",
      content: "",
    },
    columnStyle: {
      cursor: "pointer",
    },
    // color:'#4BA7B3',
    legend: false,
    theme: {
      colors20: [
        "#0F1C73",
        "#1A449F",
        "#2B67BA",
        "#3C88C0",
        "#4BA7B3",
        "#5AC39B",
        "#6ADD7F",
        "#A8DD6A",
        "#DDDD6A",
      ],
    },
    xAxis: {
      label: {
        formatter: memoizedFormatter,
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
    },
    yAxis: {
      // max: isNaN(max(map(report, 'ratio'))) ? 100 : max(map(report, 'ratio')) + 15,
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };
  return (
    <>
      <StyledTitle>Headcount by Sector</StyledTitle>
      <StyledChartContainer>
        <Column {...config} />
      </StyledChartContainer>
    </>
  );
}

export default DemographicsHeadcountSector;
