import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Layout } from 'antd';

import './App.css';

import {
  BrowserRouter as Router,
} from 'react-router-dom';
import styled from 'styled-components';
import { jwtDecode } from "jwt-decode";
// Components
import SideBar from 'molecules/SideMenu';
import Header from 'molecules/Header';
import Login from 'pages/Login';
import Container from 'pages/Container';
import { STATUS_CODE } from 'common/Constants';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setIsUserLoggedIn,
  setSystemDate,
  setUsernName,
  setLoadingDataFirstTime,
  setBreakTypesList,
  setDepartmentList,
  setLeaveCategoryList,
  setLocationList,
  setPunchActions,
  setUserRole,
  setDepartmentId,
  setRoleList,
  setCheckedIn,
  setCheckInLocationId,
  setIsOnBreak,
  setUserList,
  setStatusList,
  setAllDepartmentList,
  setPunchId,
  setBreakId,
  toggleProcessingModal,
  setShiftTimeMaster,
  setUserProfile,
  clearStore,
  setProfilePic,
  setClientid,
  setSupervisorList,
  setLevelList,
} from 'redux/actions';
import { getIsUserLoggedIn, getUserName, getSysteDate} from 'redux/selectors';
import ProcessingModal from 'molecules/ProcessingModal';

import { get, first } from 'lodash';
import { CALL_API } from 'common/API';
import { getServerDateToMySql } from 'utils/Date';

const StyledAppDiv = styled.div`
  height: 100%;
`;

const StyledLayout = styled(Layout)`
  // ${({ isapp }) => isapp === 'true' && `-moz-transform: scale(0.80, 0.80); zoom: 0.80; zoom: 80%;`}
  // -moz-transform: scale(0.9, 0.9); /* Moz-browsers */
  // zoom: 0.9; /* Other non-webkit browsers */
  // zoom: 90%; /* Webkit browsers */
`;

const App = ({
  isApp,
  activePage,
  userName,
  isUserLoggedIn,
  setUserLoggedIn,
  saveSystemDate,
  setBreakTypes,
  saveDepartmentList,
  saveAllDepartmentList,
  saveLeaveCategoryList,
  saveLocationList,
  savePunchActions,
  saveUsernName,
  saveUserRole,
  toggleLoadingDataFirstTime,
  saveDepartmentId,
  saveRoleList,
  updateCheckInLocationId,
  updateIsOnBreak,
  updateCheckedIn,
  updateUserList,
  saveStatusList,
  savePunchId,
  saveBreakId,
  updateProcessingModal,
  saveShiftTimeMaster,
  saveUserProfile,
  removeStoreData,
  saveProfilePic,
  saveClientid,
  saveSupervisorList,
  saveLevelList
}) => {
  const [pageTitle, setPageTitle] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  const [intervalID, setIntervalID] = useState();
  const [open, setOpen] = useState(false);
  

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setCollapsed(!collapsed);
  };



  const isLoggedIn = () => {
    setUserLoggedIn(!isUserLoggedIn);
    toggleLoadingDataFirstTime(true);
    fetchMasterData(true);
    getPreview()
  };

  const updatePageTitle = (title) => {
    setPageTitle(title);
  };

  let defaultKey = '';

  useEffect(() => {
    if (isUserLoggedIn) {
      const intId = setInterval(() => {
        saveSystemDate(startTimeCounter());
      }, 1000);
      setIntervalID(intId);
      setUserLoggedIn(true);
      // toggleLoadingDataFirstTime(true);
      fetchMasterData(false);
      getPreview()
    } else {
      clearInterval(intervalID);
    }
  }, [isUserLoggedIn]);

  useLayoutEffect(() => {
    clearInterval(intervalID);
    updateProcessingModal(false);
    if (window.innerWidth < 567) {
      toggleDrawer();
    }
  }, [])

  const startTimeCounter = () => {
    const d = new Date();
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    const nd = new Date(utc + (3600000 * (+3)));
    return getServerDateToMySql(nd.toLocaleString('en-US'));
  }

  const redirectToNewDomain = () => {
    const newDomain = 'https://www.tymeplus.co';
    window.location.href = newDomain;
  };

  // for local use
  // const localtoken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJZCI6IkJESVg0RVciLCJ1c2VySWQiOiJzYW5kZWVwLmtoYXByZUBiZG8tZWEuY29tIiwidXNlck5hbWUiOiJTYW5kZWVwIEtoYXByZSIsImlhdCI6MTcyMDAyMjI5MCwiZXhwIjoxNzIwMDUxMDkwfQ.LtGo8UPS1tjNnlT7DgGsuOMqjrlLsHWgD_KrUT_xH28"
  // useEffect(() => {
  //     window.localStorage.setItem("token", localtoken);
  //     window.history.pushState("", "", "/");
  //   isLoggedIn()
  // }, [])
  

  useEffect(() => {
    const jwtToken = new URLSearchParams(window.location.search).get('jwtToken');
    if ( jwtToken != null ){
      window.localStorage.setItem("token", jwtToken);
      
    }
    // if (!window.localStorage.getItem("token")) {
    //   redirectToNewDomain();
    // }
    window.history.pushState("", "", "/");
    isLoggedIn()
  }, [])
  

  const fetchMasterData = async (fetch_master_data) => {
    const clientId = jwtDecode(window.localStorage.getItem("token"))?.clientId;
    const {
      code,
      first_name,
      last_name,
      // role_id,
      breakList,
      roleList,
      departmentList,
      departmentsAssignedToUser,
      leaveCategories,
      locations,
      punchActions,
      location_id,
      punch_action_id,
      userList,
      statusList,
      shiftTimeMaster,
      break_id = '',
      name,
      location_name,
      joining_date,
      username,
      email_id,
      job_title,
      department,
      employer_id,
      payroll,
      staff_department_id,
      clientid,
      supervisorList,
      levelList
    } = await CALL_API(`master-data/${clientId}`, 'post', {
      fetch_master_data
    });
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        setBreakTypes(breakList);
        saveDepartmentList(departmentsAssignedToUser);
        saveAllDepartmentList(departmentList);
        // saveDepartmentId(get(first(departmentsAssignedToUser), 'department_id', ''));
        saveDepartmentId([get(first(departmentsAssignedToUser), 'department_id', '')]);
        saveLeaveCategoryList(leaveCategories);
        saveLocationList(locations)
        savePunchActions(punchActions);
        saveUsernName(`${first_name} ${last_name}`);
        // saveUserRole(role_id);
        saveRoleList(roleList);
        updateUserList(userList);
        saveStatusList(statusList);
        saveShiftTimeMaster(shiftTimeMaster);
        saveSupervisorList(supervisorList);
        saveLevelList(levelList);
        saveUserProfile({
          location_name,
          joining_date,
          username,
          email_id,
          job_title,
          department,
          payroll,
          employer_id,
          staff_department_id
        })
      }
      // saveSystemDate(getServerDateToMySql(date_time))
      updateCheckInLocationId(location_id);
      updateIsOnBreak(break_id ? true : false);
      saveBreakId(break_id);
      savePunchId(punch_action_id);
      saveClientid(clientid);
      updateCheckedIn(punch_action_id ? true : false);
      toggleLoadingDataFirstTime(false);
      if (punch_action_id) {
        window.localStorage.setItem('login_reminder', 'true');
      }
    } else {
      window.localStorage.removeItem('Tymeplusjwt');
      window.localStorage.removeItem('login_reminder');
      setUserLoggedIn(false);
      removeStoreData();
    }
  }

  const getPreview = async () => {
    // const { code, url } = await CALL_API('profile-view', 'post', {
    // });
    // if (code === STATUS_CODE.SUCCESS) {
    //   saveProfilePic(url)
    // }
  }



  return (
    <StyledAppDiv>

      {/* {isUserLoggedIn && ( */}
        <>
          <Router>
            <StyledLayout isapp={`${navigator.userAgent === 'application'}`}>

              <SideBar
                open={open}
                onClose={onClose}
                setPageTitle={updatePageTitle}
                defaultKey={defaultKey}
                activePage={activePage}
                collapsed={collapsed}
                toggleCollapse={toggleDrawer}
              >
              </SideBar>
              <Layout>
                <Header
                  showDrawer={showDrawer}
                  pageTitle={pageTitle}
                  setLoggedIn={isLoggedIn}
                  setPageTitle={updatePageTitle}
                  toggleCollapse={toggleDrawer}
                  collapsed={collapsed}
                ></Header>
                <Container />
              </Layout>
            </StyledLayout>
          </Router>
        </>
      {/* )} */}
       {/* {!isUserLoggedIn && <Login setLoggedIn={isLoggedIn} />} */}
      <ProcessingModal />
    </StyledAppDiv>
  );
};

const mapStateToProps = state => ({
  isUserLoggedIn: getIsUserLoggedIn(state),
  userName: getUserName(state),
  systeDate: getSysteDate(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setUserLoggedIn: setIsUserLoggedIn,
    saveSystemDate: setSystemDate,
    saveUsernName: setUsernName,
    setBreakTypes: setBreakTypesList,
    saveDepartmentList: setDepartmentList,
    saveAllDepartmentList: setAllDepartmentList,
    saveLeaveCategoryList: setLeaveCategoryList,
    saveLocationList: setLocationList,
    savePunchActions: setPunchActions,
    saveUserRole: setUserRole,
    toggleLoadingDataFirstTime: setLoadingDataFirstTime,
    saveDepartmentId: setDepartmentId,
    saveRoleList: setRoleList,
    updateCheckedIn: setCheckedIn,
    updateCheckInLocationId: setCheckInLocationId,
    updateIsOnBreak: setIsOnBreak,
    updateUserList: setUserList,
    saveStatusList: setStatusList,
    savePunchId: setPunchId,
    saveBreakId: setBreakId,
    updateProcessingModal: toggleProcessingModal,
    saveShiftTimeMaster: setShiftTimeMaster,
    saveUserProfile: setUserProfile,
    removeStoreData: clearStore,
    saveProfilePic: setProfilePic,
    saveClientid: setClientid,
    saveSupervisorList: setSupervisorList,
    saveLevelList: setLevelList,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(App);
