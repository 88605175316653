import { Col, DatePicker, Form, Row, Select } from "antd";
import { CALL_FEATCH_API } from "common/API";
import { MANAGER, SUPERVISOR } from "common/Constants";
import { flatMap, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getRoleList, getSupervisorList, getUserList } from "redux/selectors";
import styled from "styled-components";

const StylededFilterDiv = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;
/* .ant-select{
  width: 150px;
} */
/* .ant-picker.ant-picker-disabled{
  width: 250px !important;
} */
@media (max-width: 1376px) {
  justify-content: flex-start;
  gap: 5px;
}
@media (max-width: 504px) {
  .ant-select{
  width: 200px;
}
.ant-picker{
  width: 200px !important;
}
}
`

const Filters = ({setValue, value, roleList, userList, supervisorList}) => {
  const [form] = Form.useForm();
  const containerRef = useRef(null);
  const { Option } = Select;
  const [managingOfficeData, setManagingOfficeData] = useState([]);
  const [organisationLevelData, setOrganisationLevelData] = useState([]);
  const [competencyQCData, setCompetencyQCData] = useState([]);

  const filteredUserName = userList?.filter(item => item.roleid.includes("2"))?.map(item => item.fullname);

  // const handleValuesChange = (changedValues, allValues) => {
  //   setValue({
  //     ...value,
  //     managingoffice: form.getFieldValue('managingoffice'),
  //     organisationlevel: form.getFieldValue('organisationlevel'),
  //     competencyQC: form.getFieldValue('competencyQC'),
  //   });
  // };

  const fetchData = async () => {
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level1`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setManagingOfficeData(result);
    }
  };

  

  const competencyQCfetchData = async () => {
    const filtervalue = managingOfficeData.filter((item) =>
      form.getFieldValue("managingoffice").includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({ name: item.name, value }))
      );
      setCompetencyQCData(result);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   form.setFieldsValue({ organisationlevel: [], competencyQC: [] });
  // }, [form.getFieldValue("managingoffice")]);

  return (
    <div className="mt-2">
      <Form
        form={form}
        initialValues={value}
        // onValuesChange={handleValuesChange}
      >
        <Row gutter={16}>
        <Col xs={24} sm={24} md={8} lg={4} ref={containerRef}>
  <Form.Item name="date">
    <DatePicker
      allowClear={true}
      // picker="year"
      placeholder="Start Date"
      className="w-100"
      format="DD-MM-YYYY"
      getPopupContainer={() => containerRef.current}
      onChange={()=>{
        if(form.getFieldValue('date')){
          setValue({
            ...value,
            date: form.getFieldValue('date'),
            end_date: moment(form.getFieldValue('date')).add(1, 'year')
          });
          form.setFieldsValue({end_date: moment(form.getFieldValue('date')).add(1, 'year')});
        }
        else{
          setValue({
            ...value,
            date: "",
            end_date:""
          });
          form.setFieldsValue({date: ""});
          form.setFieldsValue({end_date: ""});
        }
      }}
    />
  </Form.Item>
</Col>
<Col xs={24} sm={24} md={8} lg={4} ref={containerRef}>
  <Form.Item name="end_date">
    <DatePicker
       allowClear={true}
      placeholder="End Date"
      // picker="year"
      className="w-100"
      format="DD-MM-YYYY"
      disabled={!form.getFieldValue('date')}
      disabledDate={(current) => {
        const selectedDate = form.getFieldValue('date');
        return current && (current > moment(selectedDate).add(12, 'month') || current < moment(selectedDate).startOf('day'));
      }}
      getPopupContainer={() => containerRef.current}
      onChange={()=>{
        if(form.getFieldValue('end_date')){
          setValue({
            ...value,
            end_date: form.getFieldValue('end_date'),
          });
        }
        else{
          setValue({
            ...value,
            date: "",
            end_date:""
          });
          form.setFieldsValue({date: ""});
          form.setFieldsValue({end_date: ""});
        }
      }}
    />
  </Form.Item>
</Col>
<Col xs={24} sm={24} md={8} lg={4} ref={containerRef}>
            <Form.Item name="managingoffice">
              <Select
                showSearch
                placeholder="Country"
                optionFilterProp="value"
                mode="multiple"
                options={managingOfficeData}
                fieldNames={{
                  label: "value",
                  value: "value",
                }}
                getPopupContainer={() => containerRef.current}
                onChange={() => {
                  competencyQCfetchData();
                  // setCompetencyQCData([]);
                  form.setFieldsValue({
                    // organisationlevel: [],
                    competencyQC: [],
                  });
                  setValue({
                    ...value,
                    managingoffice: form.getFieldValue('managingoffice'),
                    organisationlevel: form.getFieldValue('organisationlevel'),
                    competencyQC: form.getFieldValue('competencyQC')
                  });
                }}
              />
            </Form.Item>
          </Col>
          
          <Col xs={24} sm={24} md={8} lg={4} ref={containerRef}>
            <Form.Item name="competencyQC">
              <Select
                showSearch
                placeholder="Competency/ QC"
                optionFilterProp="value"
                mode="multiple"
                getPopupContainer={() => containerRef.current}
                disabled={competencyQCData?.length === 0 && form.getFieldValue('organisationlevel')?.length === 0 }
                options={competencyQCData}
                fieldNames={{
                  label: "value",
                  value: "value",
                }}
                onChange={() => {
                  form.setFieldsValue({
                    serviceline: [],
                    subserviceline: [],
                    competencyrole: [],
                  });
                  setValue({
                    ...value,
                    competencyQC: form.getFieldValue('competencyQC'),
                  });
                }}
              />
            </Form.Item>
          </Col>
          
          <Col xs={24} sm={24} md={8} lg={4} ref={containerRef}>
            <Form.Item name="officesupervisor">
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
                onChange={()=>{
                  setValue({
                    ...value,
                    officesupervisor: form.getFieldValue('officesupervisor'),
                  });
                }}
              >
                <Option value="">Office Supervisor</Option>
                {supervisorList?.map((value, index) => (
                        <Select.Option
                          key={index}
                          value={value?.name}
                          label={value?.name}
                        >
                          {value?.name}
                        </Select.Option>
                      ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  roleList: getRoleList(state),
  userList: getUserList(state),
  supervisorList: getSupervisorList(state),
});

export default connect(mapStateToProps,null)(Filters);
