import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import moment from "moment";
import { getClientid } from "redux/selectors";
import { connect } from "react-redux";
import { Spin } from "antd";
import DetailsModal from "organisms/DetailsModal";

function QualificationByAge({ value, clientid }) {
  const initialDataList = [
    { Age: "20-30", Qualification: "Bachelor", Count: 0 },
    { Age: "30-40", Qualification: "Bachelor", Count: 0 },
    { Age: "40-50", Qualification: "Bachelor", Count: 0 },
    { Age: "50-60", Qualification: "Bachelor", Count: 0 },
    { Age: "60-70", Qualification: "Bachelor", Count: 0 },
    { Age: "20-30", Qualification: "Diploma", Count: 0 },
    { Age: "30-40", Qualification: "Diploma", Count: 0 },
    { Age: "40-50", Qualification: "Diploma", Count: 0 },
    { Age: "50-60", Qualification: "Diploma", Count: 0 },
    { Age: "60-70", Qualification: "Diploma", Count: 0 },
    { Age: "20-30", Qualification: "Certificate", Count: 0 },
    { Age: "30-40", Qualification: "Certificate", Count: 0 },
    { Age: "40-50", Qualification: "Certificate", Count: 0 },
    { Age: "50-60", Qualification: "Certificate", Count: 0 },
    { Age: "60-70", Qualification: "Certificate", Count: 0 },
    { Age: "20-30", Qualification: "Master", Count: 0 },
    { Age: "30-40", Qualification: "Master", Count: 0 },
    { Age: "40-50", Qualification: "Master", Count: 0 },
    { Age: "50-60", Qualification: "Master", Count: 0 },
    { Age: "60-70", Qualification: "Master", Count: 0 },
    { Age: "20-30", Qualification: "Doctorate", Count: 0 },
    { Age: "30-40", Qualification: "Doctorate", Count: 0 },
    { Age: "40-50", Qualification: "Doctorate", Count: 0 },
    { Age: "50-60", Qualification: "Doctorate", Count: 0 },
    { Age: "60-70", Qualification: "Doctorate", Count: 0 },
  ];

  const [QualificationByAgeData, setQualificationByAgeData] =
    useState(initialDataList);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [plotData, setPlotData] = useState("");


  const { getQualificationByAgeData } = queryKeys;

  const payload = {
    method: "qualificationByAge",
    parameters: {
      startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date
        ? moment(value?.end_date).format("YYYY-MM-DD")
        : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || "",
    },
  };

  const payloadString = JSON.stringify(payload);
  const { key, url } = getQualificationByAgeData(clientid, payloadString);

  const { data, isLoading, refetch } = useFetch(
    key,
    url,
    {
      enabled: !!clientid,
    },
    payload
  );

  useEffect(() => {
    const dataList = data?.response?.qualificationByAge;
    if (dataList) {
      const transformedData = [...initialDataList];

      const validQualifications = [
        "Bachelor",
        "Diploma",
        "Certificate",
        "Master",
        "Doctorate",
      ];
      if (dataList.length > 0) {
        dataList.forEach((user) => {
          const birthDate = moment(user.dateofbirth);
          const currentAge = moment().diff(birthDate, "years");
          let ageRange;

          if (currentAge >= 20 && currentAge < 30) {
            ageRange = "20-30";
          } else if (currentAge >= 30 && currentAge < 40) {
            ageRange = "30-40";
          } else if (currentAge >= 40 && currentAge < 50) {
            ageRange = "40-50";
          }
          else if (currentAge >= 50 && currentAge < 60) {
            ageRange = "50-60";
          }
          else if (currentAge >= 60 && currentAge < 71) {
            ageRange = "60-70";
          } else {
            return; // Skip users outside of the specified age ranges
          }
          if (user?.educationdetails?.length > 0) {
            user.educationdetails.forEach((education) => {
              const qualification = education.awarded;
              if (validQualifications.includes(qualification)) {
                const dataItem = transformedData.find(
                  (item) =>
                    item.Age === ageRange &&
                    item.Qualification === qualification
                );
                if (dataItem) {
                  dataItem.Count += 1;
                } else {
                  transformedData.push({
                    Age: ageRange,
                    Qualification: qualification,
                    Count: 1,
                  });
                }
              }
            });
          }
        });
      }

      setQualificationByAgeData(transformedData);
    }
  }, [data]);

  const plotClick = () =>{
    const calculateAgeRange = (dateofbirth) => {
      const birthDate = moment(dateofbirth);
      const currentAge = moment().diff(birthDate, "years");
      let ageRange;

      if (currentAge >= 20 && currentAge < 30) {
        ageRange = "20-30";
      } else if (currentAge >= 30 && currentAge < 40) {
        ageRange = "30-40";
      } else if (currentAge >= 40 && currentAge < 50) {
        ageRange = "40-50";
      }
      else if (currentAge >= 50 && currentAge < 60) {
        ageRange = "50-60";
      }
      else if (currentAge >= 60 && currentAge < 71) {
        ageRange = "60-70";
      } else {
        return null; // Return null for users outside of the specified age ranges
      }

      return ageRange;
    };

    if (plotData?.data?.data) {
      const filterData = data?.response?.qualificationByAge?.filter(
        (item) => {
          const userAgeRange = calculateAgeRange(item.dateofbirth);
          return (
            item.educationdetails.some(
              (ed) => ed.awarded === plotData.data.data.Qualification
            ) && plotData?.data?.data?.Age === userAgeRange
          );
        }
      );

      setTableData(filterData);
      setIsModalVisible(true);
    }
}

useEffect(()=>{
if(plotData !== ""){
plotClick();
}
},[plotData])

  const config = {
    data: QualificationByAgeData,
    isGroup: true, // Enable grouped columns
    xField: "Age",
    yField: "Count",
    seriesField: "Qualification", // Group by the 'Qualification' field
    colorField: "Qualification",
    groupField: "Qualification", // Group by the 'Qualification' field
    color: ["#D6A5FF", "#FAC49C", "#C2FAE5", "#F26B6B", "#A39BFF"],
    animation: false,
    onReady: (plot) => {
      plot.on("plot:click", (evt) => {
        setPlotData(evt);
      });
    },
    label: {
      layout: [
        { type: "interval-adjust-position" }, // Adjust label position to avoid overlap
        { type: "interval-hide-overlap" }, // Hide overlapping labels
        { type: "adjust-color" }, // Adjust label color based on the background color
      ],
    },
    yAxis: {
      title: {
        text: "Head Count",
        style: {
          fontSize: 12,
          fontWeight: 500,
        },
      },
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };

  return (
    <>
      <h6 className="mt-2">Qualification By Age:</h6>
      <Spin spinning={isLoading}>
        <div className="box-container">
          <Column {...config} />
        </div>
      </Spin>
      <DetailsModal
        isopen={isModalVisible}
        isCancel={() => setIsModalVisible(false)}
        tabledata={tableData}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(QualificationByAge);
