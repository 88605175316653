const queryKeys = {
  getActivityLog: (queryparams) => ({
    key: `get-activity-log-${queryparams}`,
    url: `activitylog${queryparams}`,
  }),
  getRoleDataList: (clientId) => ({
    key: `get-role-list`,
    url: `owner-roles/${clientId}`,
  }),
  getGenderDistributionData: (clientId, query) => ({
    key: `get-gender-distribution${query}`,
    url: `mgview-people-dashboard/${clientId}`,
  }),
  getEmployeePerLocationData: (clientId, query) => ({
    key: `get-employee-per-location${query}`,
    url: `mgview-people-dashboard/${clientId}`,
  }),
  getEmployeesTotalExperienceData: (clientId, query) => ({
    key: `get-employee-total-experience${query}`,
    url: `mgview-people-dashboard/${clientId}`,
  }),
  getHireAndAttritionTrendByMonthData: (clientId, query) => ({
    key: `get-hire-and-attrition-tren-by-month${query}`,
    url: `mgview-people-dashboard/${clientId}`,
  }),
  getPeopleDashboardCommonCountData: (clientId, query) => ({
    key: `get-people-dashboard-common-count${query}`,
    url: `mgview-people-dashboard/${clientId}`,
  }),
  getHeadCountByAgeData: (clientId, query) => ({
    key: `get-head-count-by-age${query}`,
    url: `mgview-diversity-inclusion/${clientId}`,
  }),
  getEmployeeHiredByMonthData: (clientId, query) => ({
    key: `get-employee-hired-by-month${query}`,
    url: `mgview-diversity-inclusion/${clientId}`,
  }),
  getHeadCountByGradeCode: (clientId, query) => ({
    key: `get-head-count-by-grade-code${query}`,
    url: `mgview-diversity-inclusion/${clientId}`,
  }),
  getQualificationCommonCountData: (clientId, query) => ({
    key: `get-diversity-and-inclusion-common-count${query}`,
    url: `mgview-qualification/${clientId}`,
  }),
  getHeadCountByLevelData: (clientId, query) => ({
    key: `get-head-count-by-level${query}`,
    url: `mgview-diversity-inclusion/${clientId}`,
  }),
  getAttritionbyAge: (clientId, query) => ({
    key: `get-attrition-by-age${query}`,
    url: `mgview-retention-attrition/${clientId}`,
  }),
  getAttritionByGradeCode: (clientId, query) => ({
    key: `get-attrition-by-gradecode${query}`,
    url: `mgview-retention-attrition/${clientId}`,
  }),
  getAttritionByManager: (clientId, query) => ({
    key: `get-attrition-by-manager${query}`,
    url: `mgview-retention-attrition/${clientId}`,
  }),
  getAttritionByDepartment: (clientId, query) => ({
    key: `get-attrition-by-department${query}`,
    url: `mgview-retention-attrition/${clientId}`,
  }),
  getAttritionByPerformance: (clientId, query) => ({
    key: `get-attrition-by-Performance${query}`,
    url: `mgview-retention-attrition/${clientId}`,
  }),
  getQualificationByTenure: (clientId, query) => ({
    key: `get-qualification-by-Tenure${query}`,
    url: `mgview-qualification/${clientId}`,
  }),
  getQualificationByGradeCode: (clientId, query) => ({
    key: `get-qualification-by-GradeCode${query}`,
    url: `mgview-qualification/${clientId}`,
  }),
  getQualificationByAgeData: (clientId, query) => ({
    key: `get-qualification-by-age${query}`,
    url: `mgview-qualification/${clientId}`,
  }),
  getQualificationByGenderData: (clientId, query) => ({
    key: `get-qualification-by-Gender${query}`,
    url: `mgview-qualification/${clientId}`,
  }),
 
};

export default queryKeys;
