import React, { useEffect, useMemo, useState } from "react";
import { Pie } from "@ant-design/plots";
import { useFetch } from "hooks/useFetch";
import queryKeys from "common/queryKey";
import moment from "moment";
import { connect } from "react-redux";
import { getClientid } from "redux/selectors";
import { filter, find, isEmpty, map } from "lodash";
import { Spin } from "antd";
import DetailsModal from "organisms/DetailsModal";

const initialData = [
  {
    type: "1-2",
    Count: 0,
  },
  {
    type: "2-3",
    Count: 0,
  },
  {
    type: "3-4",
    Count: 0,
  },
  {
    type: "4-5",
    Count: 0,
  },
];

function AttritionbyPerformance({ value, clientid }) {
  const [dataList, setDataList] = useState(initialData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [plotData, setPlotData] = useState("");


  const { getAttritionByPerformance } = queryKeys;

  const payload = {
    method: "attritionByPerformance",
    parameters: {
      startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date
        ? moment(value?.end_date).format("YYYY-MM-DD")
        : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || "",
    },
  };

  const payloadString = JSON.stringify(payload);
  const { key, url } = getAttritionByPerformance(clientid, payloadString);

  const { data, isLoading, refetch } = useFetch(
    key,
    url,
    {
      enabled: !!clientid,
    },
    payload
  );

  useEffect(() => {
    const response = data?.response?.attritionByPerformance;
    if (response) {
      const updatedData = map(initialData, (item) => {
        const update = filter(response, { range: item.type });
        return !isEmpty(update) ? { ...item, Count: update?.length } : item;
      });
      setDataList(updatedData);
    }
  }, [data]);

  const plotClick = () =>{
    if (plotData?.data?.data) {
      const filterData = data?.response?.attritionByPerformance?.filter(
        (item) => plotData.data.data.type === item?.range
      );
      setTableData(filterData);
      setIsModalVisible(true);
    }
}

useEffect(()=>{
    if(plotData !== ""){
    plotClick();
    }
},[plotData])

  const config = {
    appendPadding: 9,
    data: dataList,
    angleField: "Count",
    colorField: "type",
    paddingRight: 80,
    innerRadius: 0.6,
    radius: 0.6,
    animation: false,
    onReady: (plot) => {
      plot.on("plot:click", (evt) => {
       setPlotData(evt);
      });
    },
    label: {
      text: "Count",
      content: ({ type, Count, percent }) =>
        `${Count} (${(percent * 100).toFixed(0)}%)`,
      style: {
        fontWeight: "bold",
      },
    },
    legend: {
      position: "right",
      title: {
        text: "Total appraisal band", // Title for the legends
        style: {
          fontSize: 12,
          fontWeight: "normal",
          fill: "black", // Customize title text color if needed
        },
      },
    },
    annotations: [
      {
        type: "text",
        style: {
          text: "",
          x: "0%",
          y: "0%",
          textAlign: "center",
          fontSize: 40,
          fontStyle: "bold",
        },
      },
    ],
    statistic: {
      title: {
        offsetY: -4,
        style: {
          fontWeight: "bold",
        },
        customHtml: () => "",
      },
      content: {
        offsetY: 4,
        style: {
          height: "20px",
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "14px",
          fontWeight: "normal",
        },
        customHtml: () => "",
      },
    },
    color: ["#9CD3FA", "#FAC2F8", "#FAC49C", "#A39BFF"],
  };

  return (
    <>
      <h6 className="mt-2">Attrition by performance:</h6>
      <div className="box-container">
        <Spin spinning={isLoading}>
          <Pie {...config} />
        </Spin>
      </div>
      <DetailsModal
        isopen={isModalVisible}
        isCancel={() => setIsModalVisible(false)}
        tabledata={tableData}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(AttritionbyPerformance);
