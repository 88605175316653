import React, {useState, useEffect} from 'react';

// Components
import DashboardTop from 'molecules/DashboardTop';
import NonStaffDashboard from 'molecules/NonStaffDashboard';
import { Row, Col } from 'antd';
import styled from '@emotion/styled';

// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId
} from 'redux/selectors';


const StyledRow = styled(Row)`
  padding-top: 20px;
`;

function Dashboard({ userRoleId }) {
  return (
    <>
      {/* <DashboardTop /> */}
      <NonStaffDashboard />
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state)
});

export default connect(mapStateToProps, null)(Dashboard);
