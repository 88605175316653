import React, { useEffect, useState } from "react";
import { Line } from "@ant-design/plots";
import styled from "@emotion/styled";
import queryKeys from "common/queryKey";
import { connect } from "react-redux";
import { getClientid } from "redux/selectors";
import moment from "moment";
import { useFetch } from "hooks/useFetch";
import { Spin } from "antd";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function HireandAttritionTrendbyMonth({ value, clientid }) {
  const [hireCountByMonthData, setHireCountByMonthData] = useState([]);
  const [attritionCountByMonthData, setAttritionCountByMonthData] = useState([]);

  const { getHireAndAttritionTrendByMonthData } = queryKeys;

  const startDate = value?.date ? moment(value?.date).startOf("month") : moment().startOf("month").clone().subtract(12, "months").startOf("month");
  const endDate = startDate.clone().add(12, "months").endOf("month");

  const payload = {
    method: "trendsByMonth",
    parameters: {
      startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || ""
    }
  };

  const payloadString = JSON.stringify(payload);
  const { key, url } = getHireAndAttritionTrendByMonthData(clientid, payloadString);
  const { data, isLoading, refetch } = useFetch(key, url, { enabled: !!clientid }, payload);

  useEffect(() => {
    if (data) {
      const hireData = data?.response?.hireCountByMonth || [];
      const attritionData = data?.response?.attritionCountByMonth || [];
      setHireCountByMonthData(Array.isArray(hireData) ? hireData : []);
      setAttritionCountByMonthData(Array.isArray(attritionData) ? attritionData : []);
    }
  }, [data]);

  const months = [];
for (let date = startDate.clone(); date.isBefore(endDate) || date.isSame(endDate, "month"); date.add(1, "month")) {
  months.push({
    month: date.format("MMM YYYY"),
    fullMonth: date.format("MMMM"),
    year: date.year()
  });
}

  const mergeData = () => {
    return months.map((month) => {
      const hireData = hireCountByMonthData.find((item) => item.month === month.fullMonth && item.year === month.year);
      const attritionData = attritionCountByMonthData.find((item) => item.month === month.fullMonth && item.year === month.year);
      return {
        month: month.month,
        hireCount: hireData ? hireData.count : 0,
        attritionCount: attritionData ? attritionData.count : 0
      };
    });
  };

  const formattedData = mergeData();

  const finalData = [];
  formattedData.forEach((item) => {
    finalData.push({ month: item.month, count: item.hireCount, type: "Hire" });
    finalData.push({ month: item.month, count: item.attritionCount, type: "Attrition" });
  });

  const config = {
    data: finalData,
    xField: "month",
    yField: "count",
    seriesField: "type",
    height: 300,
    label: false,
    animation: false,
    color: ["#B0DFFB", "#FAC49C"],
    point: {
      size: 5
    },
    tooltip: {
      showMarkers: false
    },
    state: {
      active: {
        style: {
          shadowBlur: 4
        }
      }
    },
    xAxis: {
      title: {
        text: "Month",
        style: {
          fontSize: 12,
          fontWeight: 500
        }
      },
      label: {
        autoRotate: true,
        autoHide: true,
        style: {
          fontSize: 12,
          fill: '#666',
        }
      }
    },
    yAxis: {
      title: {
        text: "Head Count",
        style: {
          fontSize: 12,
          fontWeight: 500
        }
      },
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      }
    },
    interactions: [
      {
        type: "marker-active"
      }
    ],
    lineStyle: ({ type }) => {
      if (type === "Attrition") {
        return {
          lineDash: [4, 4] // dashed line
        };
      } else {
        return {
          lineDash: [] // solid line
        };
      }
    }
  };

  return (
    <>
      <h6 className="mt-2">Hire and attrition trend by month:</h6>
      <Spin spinning={isLoading}>
        <div className="box-container">
          <Line {...config} />
        </div>
      </Spin>
    </>
  );
}

const mapStateToProps = (state) => ({
  clientid: getClientid(state)
});

export default connect(mapStateToProps, null)(HireandAttritionTrendbyMonth);
