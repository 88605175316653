import { Col, Modal, Row, Switch, Table } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  getAllDepartmentList,
  getLocations,
  getRoleList,
} from "redux/selectors";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledModal = styled(Modal)`
  .title {
    font-size: 20px;
  }
  .headtext {
    color: #7b8093;
    font-size: 16px;
  }
  .valuetext {
    color: #000;
    font-size: 14px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-body{
    padding: 0;
    max-height: 80vh;
    overflow-y: auto;
  }
`;

const DetailsModal = ({
  isopen,
  isCancel,
  title,
  department,
  location,
  departmentList,
  locationList,
  tabledata,
  employeeCount,
  onEdit,
  onDelete,
  details,
  isDisable
}) => {
  const departmentdata =
    departmentList?.filter((item) =>
      department?.includes(item.department_id)
    ) || null;

  const locationdata =
    locationList?.filter((item) => location?.includes(item.location_id)) ||
    null;
    

    const cursorstyle = {cursor: "pointer"}

    const columns = [
        {
            title: "Employee name",
            dataIndex: "firstname",
            key: "firstname",
            width: "15%",
            render: (_,record)=>{
                return <Link to={`/profile-details/${record.userid}/?type=manage`}>
                  <span className="text-primary">{record?.firstname} {record?.lastname}</span>
                </Link>
            }
          },
          {
            title: "Employee ID",
            dataIndex: "employeeid",
            key: "employeeid",
            width: "15%",
          },
          {
            title: "Email",
            dataIndex: "userid",
            key: "userid",
            width: "15%",
          },
    ]

  return (
    <StyledModal
    //   title="Details"
      width={700}
      open={isopen}
      onCancel={isCancel}
      footer={[]}
      centered
    >
      <div className="">
      <Table
        className=""
        dataSource={tabledata}
        columns={columns}
        rowKey="userid"
        pagination={false}
      />
      </div>
    </StyledModal>
  );
};
const mapStateToProps = (state) => ({
  locationList: getLocations(state),
  departmentList: getAllDepartmentList(state),
  roleList: getRoleList(state),
});

export default connect(mapStateToProps)(DetailsModal);
