import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Spin } from 'antd';
import { unionBy, has } from 'lodash';
import { Row, Col } from 'antd';
import { DEVICE } from 'common/Device';
import { DatePicker } from 'antd';
import YearwiseTotalHoursOvertime from'../molecules/YearwiseTotalHoursOvertime'
import YearwiseHeadCount from 'molecules/YearwiseHeadCount';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CALL_API } from 'common/API';
import moment from 'moment';
import SickLeaveGraph from 'molecules/SickLeaveGraph';
import { connect } from 'react-redux';
import { getClientid } from 'redux/selectors';

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  .top_row{
    width:100%;
    display:flex;
    justify-content:space-between;
  }
  .select_field{
    display: flex;
    gap:10px;
  }
  .selectbox{
    width:200px;
  }
  @media ${DEVICE.tablet}  {
    .select_field{
      width:100%;
      display: flex;
      flex-direction:column;
    }
    .selectbox{
      width:100%;
    }
    .align-right{
      width:100%;
      margin-top:10px;
      float:right;
    }
  }
`;

function Trends({ clientid }) {
  const containerRef = useRef(null);
  const defaultEndYear =  moment().year();
  const [ overtimeData, setOvertimeData ] = useState([])
  const [ headCountData, setHeadCountData ] = useState([])
  const [ startYear, setStartYear ] = useState(defaultEndYear-5)
  const [ endYear, setEndYear ] = useState(defaultEndYear)
  const [ displayReportLoader, toggleReportLoader ] = useState(false);

  const generatePDF = () => {
    const reportElement = containerRef.current;
    const reportHeight = reportElement.clientHeight;
    html2canvas(reportElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new JsPDF('p', 'pt', [reportHeight, reportHeight]);
      pdf.addImage(imgData, 'PNG', 0, 0, reportHeight, reportHeight);
      pdf.save('Trends.pdf');
    });
  }
  
  function convertTimeStringToHoursAndMinutes(timeString) {
    const duration = moment.duration(timeString);
    const hours = duration.hours();
    const minutes = duration.minutes();
    return moment({ hours, minutes }).format("HH:mm");
}

function convertTimeToDecimal(timeString) {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours + minutes / 100; // Convert minutes to decimal by dividing by 100
}


  const getData = async() => {
    toggleReportLoader(true)
    const initialData = [];

    for (let year =  parseInt(startYear); year <=  parseInt(endYear); year++) {
      initialData.push({
        Hours: "00:00:00",
        Year: year,
        StaffCount: 0,
        value: 0
      });
    }

    const {
      totalHoursOfOverTimeData,
      headCountEvolutionData
    } = await CALL_API(`mgview-trends/${clientid}`, 'post', {
      method: 'adminTrends',
      parameters: {
        start_year: startYear,
        end_year: endYear
      },
    });
    const mappedOvertimeData = totalHoursOfOverTimeData.map(item => ({
      Year: item.year,
      Hours: item.hours
    }));
    const mappedHeadCountData = headCountEvolutionData.map(item => ({
      Year: item.year,
      StaffCount: item.staff_count
    }));

    setOvertimeData(() => {
      const mergedData = unionBy(initialData, mappedOvertimeData, 'Year');
      mergedData.forEach(item => {
        const Hours2 = (mappedOvertimeData.find(data => data.Year === item.Year) || {}).Hours || "00:00:00";
        let hourVal = convertTimeStringToHoursAndMinutes(Hours2);
        item.Hours = hourVal
        item.value = convertTimeToDecimal(hourVal)
      });
      return mergedData;
    })

    const data = () => {
      const mergedData = unionBy(initialData, mappedHeadCountData, 'Year');
      mergedData.forEach(item => {
        const StaffCount = (mappedHeadCountData.find(data => data.Year === item.Year) || {}).StaffCount || 0;
        item.StaffCount = StaffCount
      });
      return mergedData;
    }

    const reportData = data().map(obj => {
      const Year = obj.Year;
      if (has(obj, 'StaffCount')) {
        return { Year, type: 'Staff', count: obj.StaffCount };
      }
      return null;
    }).filter(Boolean);

    setHeadCountData(reportData);

    toggleReportLoader(false);
  }

  function handleStartYearChange(yearMoment) {
    setStartYear(yearMoment.format('YYYY'));
  }
 
  function handleEndYearChange(yearMoment) {
    setEndYear(yearMoment.format('YYYY'));   
  }
  useEffect(() => {
    getData();
  }, [startYear, endYear]);

  return (
    <>      
      <StyledTitle  className='page-title-head'>
        Trends
      </StyledTitle>
      <StyledFilterRow>
        <Row className='top_row' >
          <div className='select_field' ref={containerRef}>
            <DatePicker.YearPicker
              getPopupContainer={() => containerRef.current}
              className='selectbox'
              defaultValue={moment(`${startYear}`, 'YYYY')}
              placeholder='Select from year'
              clearIcon={null}
              onChange={handleStartYearChange}
            />
            <DatePicker.YearPicker
              getPopupContainer={() => containerRef.current}
              className='selectbox'
              defaultValue={moment(`${defaultEndYear}`, 'YYYY')}
              placeholder='Select to year'
              clearIcon={null}
              onChange={handleEndYearChange}
            />
          </div>
        </Row>
      </StyledFilterRow>
      <StyledFilterRow gutter={16}>
        <Col md={24} sm={24} xs={24} xl={12}>
          <Spin spinning={displayReportLoader}>
            <YearwiseTotalHoursOvertime report={overtimeData} startYear={startYear} endYear={endYear}/>
          </Spin>
        </Col>
        <Col md={24} sm={24} xs={24} xl={12}>
          <Spin spinning={displayReportLoader}>
            <YearwiseHeadCount report={headCountData}/>
          </Spin>
        </Col>
      </StyledFilterRow>
    </>
  );
}

const mapStateToProps = state => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(Trends);
