import React, { useEffect, useState } from "react";
import { Bar } from "@ant-design/plots";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { Spin } from "antd";
import { concat, includes, isNaN, map, max, size } from "lodash";
import { getAllDepartmentList } from "redux/selectors";
import { connect } from "react-redux";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function ProductivityManagerToStaffRatio({ allDepartmentList }) {
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const data = [
    {
      type: "HR Management",
      info: `Staff`,
      count:Math.floor(Math.random() * 100) + 1
    },
    {
      type: "Research And Development",
      info: `Staff`,
      count:Math.floor(Math.random() * 100) + 1
    },
    {
      type: "Security Department",
      info: `Staff`,
      count:Math.floor(Math.random() * 100) + 1
    },
    {
      type: "Quality Assurance",
      info: `Staff`,
      count:Math.floor(Math.random() * 100) + 1
    },
    {
      type: "DevOps",
      info: `Staff`,
      count:Math.floor(Math.random() * 100) + 1
    },
    {
      type: "Product Development",
      info: `Staff`,
      count:Math.floor(Math.random() * 100) + 1
    },
    {
      type: "Developer",
      info: `Staff`,
      count:Math.floor(Math.random() * 100) + 1
    },
    {
      type: "Marketing",
      info: `Staff`,
      count:Math.floor(Math.random() * 100) + 1
    },
    {
      type: "Finance",
      info: `Staff`,
      count:Math.floor(Math.random() * 100) + 1
    },
    {
      type: "Learning And Development",
      info: `Staff`,
      count:Math.floor(Math.random() * 100) + 1
    },
    {
      type: "IT services",
      info: `Staff`,
      count:Math.floor(Math.random() * 100) + 1
    },
    {
      type: "Application Development",
      info: `Staff`,
      count:Math.floor(Math.random() * 100) + 1
    },
    {
      type: "HR Management",
      info: `Manager`,
      count:Math.floor(Math.random() * 20) + 1
    },
    {
      type: "Research And Development",
      info: `Manager`,
      count:Math.floor(Math.random() * 20) + 1
    },
    {
      type: "Security Department",
      info: `Manager`,
      count:Math.floor(Math.random() * 20) + 1
    },
    {
      type: "Quality Assurance",
      info: `Manager`,
      count:Math.floor(Math.random() * 20) + 1
    },
    {
      type: "DevOps",
      info: `Manager`,
      count:Math.floor(Math.random() * 20) + 1
    },
    {
      type: "Product Development",
      info: `Manager`,
      count:Math.floor(Math.random() * 20) + 1
    },
    {
      type: "Developer",
      info: `Manager`,
      count:Math.floor(Math.random() * 20) + 1
    },
    {
      type: "Marketing",
      info: `Manager`,
      count:Math.floor(Math.random() * 20) + 1
    },
    {
      type: "Finance",
      info: `Manager`,
      count:Math.floor(Math.random() * 20) + 1
    },
    {
      type: "Learning And Development",
      info: `Manager`,
      count:Math.floor(Math.random() * 20) + 1
    },
    {
      type: "IT services",
      info: `Manager`,
      count:Math.floor(Math.random() * 20) + 1
    },
    {
      type: "Application Development",
      info: `Manager`,
      count:Math.floor(Math.random() * 20) + 1
    },
    
  ];

  const mappedCollections = allDepartmentList.map((val) => ({
    type: val.short_department_name,
    ratio: `${Math.floor(Math.random() * 5) + 1}:${
      Math.floor(Math.random() * 30) + 1
    }`,
  }));

  const config = {
    data: data,
    isStack: true,
    xField: 'count',
    yField: 'type',
    seriesField: 'info',
    height: 500,
    label: {
      position: '',
      content: ''
    },
    legend: {
      layout: 'horizontal',
      position: 'top'
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
    yAxis: {
      // max: isNaN(max(map(reportData, 'count'))) ? 100 : max(map(reportData, 'count')) + 15,
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      },
      label: {
        formatter: (value) => value.slice(0, 3).toUpperCase(),
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
    },
    xAxis: {
      label: {
        formatter: (value) => value.slice(0, 3),
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },

    },
  }
  return (
    <>
      <StyledTitle>Span Of Control : Manager To Staff Ratio</StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayReportLoader}>
          <Bar {...config} />
        </Spin>
      </StyledChartContainer>
    </>
  );
}
const mapStateToProps = (state) => ({
  allDepartmentList: getAllDepartmentList(state),
});

export default connect(mapStateToProps)(ProductivityManagerToStaffRatio);
