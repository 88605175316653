import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { Spin } from "antd";
import {
  concat,
  includes,
  isNaN,
  map,
  max,
  size,
  forEach,
  flatMap,
} from "lodash";
import { Row, Col, Button } from "antd";
import { DEVICE } from "common/Device";
import { DatePicker, Space } from "antd";
import "antd/es/date-picker/style/css";
import DemographicsHeadcountSector from "../molecules/DemographicsHeadcountSector";
import DemographicsAgeDistribution from "../molecules/DemographicsAgeDistribution";
import DemographicsGenderDiversity from "../molecules/DemographicsGenderDiversity";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { CALL_API } from "common/API";
import moment from "moment";
import { connect } from "react-redux";
import { getClientid } from "redux/selectors";

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  .top_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .select_field {
    display: flex;
    gap: 10px;
  }
  .selectbox {
    width: 200px;
    // width:100%;
  }
  @media ${DEVICE.tablet} {
    .select_field {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .selectbox {
      width: 100%;
    }
    .align-right {
      width: 100%;
      margin-top: 10px;
      float: right;
    }
  }
`;

function Demographics({ clientid }) {
  const containerRef = useRef(null);
  const defaultYear = moment().year();
  const [headCount, setHeadCount] = useState([]);
  const [ageDistribution, setAgeDistribution] = useState([]);
  const [genderDiversity, setGenderDiversity] = useState([]);
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const container = useRef(null);
  const ageGroups = [
    {
      key: "age_below_30_count",
      label: "Less than 30 yrs",
    },
    {
      key: "age_30s_count",
      label: "30-39 yrs",
    },
    {
      key: "age_40s_count",
      label: "40-49 yrs",
    },
    {
      key: "age_50s_count",
      label: "50-59 yrs",
    },
    {
      key: "age_above_60_count",
      label: "Above 60 yrs",
    },
  ];

  const generatePDF = () => {
    const reportElement = containerRef.current;
    const reportHeight = reportElement.clientHeight;
    html2canvas(reportElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new JsPDF("p", "pt", [reportHeight, reportHeight]);
      pdf.addImage(imgData, "PNG", 0, 0, reportHeight, reportHeight);
      pdf.save("Demographics.pdf");
    });
  };

  const getData = async (year) => {
    toggleReportLoader(true);
    const yearString = year.format("YYYY");
    const { headcountBySectorData, ageDistributionData, genderDiversityData } =
      await CALL_API(`mgview-demographic/${clientid}`, "post", {
        method: "adminDemographics",
        parameters: {
          year: yearString,
        },
      });

    const maleData = map(genderDiversityData, (dept) => {
      return {
        department_name: dept.department_name,
        count: dept.male_count,
        sex: "male",
      };
    });

    const femaleData = map(genderDiversityData, (dept) => {
      return {
        department_name: dept.department_name,
        count: dept.female_count,
        sex: "female",
      };
    });
    const result = flatMap(ageDistributionData, (data) => {
      const sex = data.gender.toLowerCase();
      return ageGroups.map((group) => {
        return {
          sex: sex,
          age_group: group.label,
          count: data[group.key],
        };
      });
    });
    setHeadCount(headcountBySectorData);
    setAgeDistribution(result);
    setGenderDiversity(concat(maleData, femaleData));
    toggleReportLoader(false);
  };

  useEffect(() => {
    getData(moment(`${defaultYear}`, "YYYY"));
  }, []);

  useEffect(() => {
    getData(moment(`${defaultYear}`, "YYYY"));
  }, []);

  return (
    <>
      <StyledTitle className="page-title-head">Demographics</StyledTitle>
      <StyledFilterRow>
        <Row className="top_row">
          <Col ref={container}>
          <DatePicker.YearPicker
              className="selectbox"
              getPopupContainer={(trigger) => trigger.parentNode}
              defaultValue={moment(`${defaultYear}`, "YYYY")}
              clearIcon={null}
              onChange={getData}
            />
          </Col>
          {/* <Col className='align-right'>
              <Button type="primary" onClick={generatePDF}><span className='add-title'>Download as PDF</span></Button>
          </Col> */}
        </Row>
      </StyledFilterRow>
      <StyledFilterRow gutter={16} ref={containerRef}>
        <Col md={24} sm={24} xs={24} xl={12}>
          <Spin spinning={displayReportLoader}>
            <DemographicsHeadcountSector report={headCount} />
          </Spin>
        </Col>
        <Col md={24} sm={24} xs={24} xl={12}>
          <Spin spinning={displayReportLoader}>
            <DemographicsAgeDistribution report={ageDistribution} />
          </Spin>
        </Col>
        <Col md={24} sm={24} xs={24} xl={24}>
          <Spin spinning={displayReportLoader}>
            <DemographicsGenderDiversity report={genderDiversity} />
          </Spin>
        </Col>
      </StyledFilterRow>
    </>
  );
}
const mapStateToProps = (state) => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(Demographics);
