import React, { useEffect, useState } from "react";
import { Bar, Column } from "@ant-design/plots";
import queryKeys from "common/queryKey";
import moment from "moment";
import { connect } from "react-redux";
import { getClientid, getRoleList } from "redux/selectors";
import { useFetch } from "hooks/useFetch";
import { Spin } from "antd";
import DetailsModal from "organisms/DetailsModal";

function HeadcountByLevel({ value, clientid, roleList }) {
  const [headCountByLevelData, setHeadCountByLevelData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [plotData, setPlotData] = useState("");

  const { getHeadCountByLevelData } = queryKeys;

  const payload = {
    method: "headCountByLevel",
    parameters: {
      startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date
        ? moment(value?.end_date).format("YYYY-MM-DD")
        : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || "",
    },
  };

  const payloadString = JSON.stringify(payload);
  const { key, url } = getHeadCountByLevelData(clientid, payloadString);

  const { data, isLoading, refetch } = useFetch(
    key,
    url,
    {
      enabled: !!clientid,
    },
    payload
  );

  useEffect(() => {
    const dataList = data?.response?.headCountByLevel;
    if (Array.isArray(dataList)) {
      setHeadCountByLevelData(dataList);
    } else {
      setHeadCountByLevelData([]);
    }
  }, [data]);

  const initialData = [
    {
      xValue: "01-P5 & P4",
      listItem: ["P5", "P4"],
      Count: 0,
    },
    {
      xValue: "02-P1, P2 & P3",
      listItem: ["P1", "P2", "P3"],
      Count: 0,
    },
    {
      xValue: "03-C3, C4, M1 & M2",
      listItem: ["C3", "C4", "M1", "M2"],
      Count: 0,
    },
    {
      xValue: "04-B1, B2, C1 & C2",
      listItem: ["B1", "B2", "C1", "C2"],
      Count: 0,
    },
    {
      xValue: "05-A1 & A2",
      listItem: ["A1", "A2"],
      Count: 0,
    },
    {
      xValue: "06-S1,S2,S3 & S4",
      listItem: ["S1", "S2", "S3", "S4", "S5"],
      Count: 0,
    },
  ];


  const Gender = ["Male", "Female", "They / Them"];

  const mergedData = [];

  Gender.forEach((gender) => {
    initialData.forEach((role) => {
      mergedData.push({
        ...role,
        gender: gender,
      });
    });
  });
  const updatedData = mergedData.map((item) => ({ ...item }));
  headCountByLevelData.forEach((person) => {
    const grade = initialData?.filter((item) =>
      item?.listItem?.includes(person?.grade)
    );
    const gradeName = grade.map((item) => item.xValue).join(", ");
    const gender =
      person.gender === "male"
        ? "Male"
        : person.gender === "female"
        ? "Female"
        : "They / Them";
    const item = updatedData.find(
      (item) => item.xValue === gradeName && item.gender === gender
    );
    if (item) {
      item.Count += 1;
    }
  });

  const plotClick = () => {
    if(plotData?.data?.data){
    const gradeList = plotData?.data?.data?.listItem;
    const filterData = data?.response?.headCountByLevel?.filter(
      (item) =>
        gradeList.includes(item?.grade) &&
        ((plotData.data.data.gender === "Male" && item.gender === "male") ||
          (plotData.data.data.gender === "Female" &&
            item.gender === "female") ||
          (plotData.data.data.gender === "They / Them" &&
            item.gender === "They/ Them"))
    );
    setTableData(filterData);
    setIsModalVisible(true);
  }
  };

  useEffect(() => {
    if (plotData !== "") {
      plotClick();
    }
  }, [plotData]);

  const config = {
    data: updatedData,
    isGroup: true,
    xField: "Count",
    yField: "xValue",
    seriesField: "gender",
    colorField: "gender",
    groupField: "gender",
    color: ["#9CD3FA", "#FAC2F8", "#A39BFF"],
    animation: false,
    label: false,
    onReady: (plot) => {
      plot.on("plot:click", (evt) => {
        setPlotData(evt);
      });
    },

    label: {
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
      },
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
    yAxis: {
      title: {
        text: "Head Count",
        style: {
          fontSize: 12,
          fontWeight: 500,
        },
      },
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };

  return (
    <>
      <h6 className="mt-2">Headcount by Level:</h6>
      <Spin spinning={isLoading}>
        <div className="box-container">
          <Bar {...config} />
        </div>
      </Spin>
      <DetailsModal
        isopen={isModalVisible}
        isCancel={() => setIsModalVisible(false)}
        tabledata={tableData}
      />
    </>
  );
}


const mapStateToProps = (state) => ({
  clientid: getClientid(state),
  roleList: getRoleList(state),
});

export default connect(mapStateToProps, null)(HeadcountByLevel);
