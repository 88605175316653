import React, { useEffect, useState } from "react";
import { Bar } from "@ant-design/plots";
import queryKeys from "common/queryKey";
import moment from "moment";
import { useFetch } from "hooks/useFetch";
import { getClientid } from "redux/selectors";
import { connect } from "react-redux";
import { Spin } from "antd";
import DetailsModal from "organisms/DetailsModal";

function HeadcountByGradeCode({ clientid, value }) {
  const initialData = [
    {
      xValue: "01-P5 & P4",
      name: "Bachelor",
      listItem: ["P5", "P4"],
      Count: 0,
    },
    {
      xValue: "02-P1, P2 & P3",
      name: "Bachelor",
      listItem: ["P1", "P2", "P3"],
      Count: 0,
    },
    {
      xValue: "03-C3, C4, M1 & M2",
      name: "Bachelor",
      listItem: ["C3", "C4", "M1", "M2"],
      Count: 0,
    },
    {
      xValue: "04-B1, B2, C1 & C2",
      name: "Bachelor",
      listItem: ["B1", "B2", "C1", "C2"],
      Count: 0,
    },
    {
      xValue: "05-A1 & A2",
      name: "Bachelor",
      listItem: ["A1", "A2"],
      Count: 0,
    },
    {
      xValue: "06-S1,S2,S3 & S4",
      name: "Bachelor",
      listItem: ["S1", "S2", "S3", "S4", "S5"],
      Count: 0,
    },
  ];

  const [headcountByGradeCode, setHeadcountByGradeCode] = useState(initialData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [plotData, setPlotData] = useState("");

  const { getHeadCountByGradeCode } = queryKeys;

  const payload = {
    method: "headCountByGradeCode",
    parameters: {
      startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date
        ? moment(value?.end_date).format("YYYY-MM-DD")
        : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || "",
    },
  };

  const payloadString = JSON.stringify(payload);
  const { key, url } = getHeadCountByGradeCode(clientid, payloadString);

  const { data, isLoading } = useFetch(
    key,
    url,
    {
      enabled: !!clientid,
    },
    payload
  );

  useEffect(() => {
    const dataList = data?.response?.headCountByGradeCode;
    if (dataList) {
      const updatedDataList = initialData.map((dataItem) => {
        const newDataItem = { ...dataItem };
        dataList.forEach((employee) => {
          if (dataItem.listItem.includes(employee.grade)) {
            newDataItem.Count += 1;
          }
        });
        return newDataItem;
      });

      setHeadcountByGradeCode(updatedDataList);
    }
  }, [data]);

  const plotClick = () => {
    if(plotData?.data?.data){
    const filterData = data?.response?.headCountByGradeCode?.filter((item) =>
      plotData.data.data.listItem?.includes(item?.grade)
    );
    setTableData(filterData);
    setIsModalVisible(true);
  }
  };

  useEffect(() => {
    if (plotData !== "") {
      plotClick();
    }
  }, [plotData]);

  const config = {
    data: headcountByGradeCode,
    xField: "Count",
    yField: "xValue",
    color: "#9CD3FA",
    animation: false,
    onReady: (plot) => {
      plot.on("plot:click", (evt) => {
        setPlotData(evt);
      });
    },
    label: {
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    xAxis: {
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };

  return (
    <>
      <h6 className="mt-2">Headcount by Grade Code:</h6>
      <Spin spinning={isLoading}>
        <div className="box-container">
          <Bar {...config} />
        </div>
      </Spin>
      <DetailsModal
        isopen={isModalVisible}
        isCancel={() => setIsModalVisible(false)}
        tabledata={tableData}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(HeadcountByGradeCode);
