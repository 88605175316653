import React from 'react';
import { Column } from '@ant-design/plots';
import styled from '@emotion/styled';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function EmployeesPerDepartment({ report }) {


  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  

  const config = {
    padding: 'auto',
    data: report,
    height: 300,
    xField: 'department_name',
    yField: 'users',
    seriesField: 'department_name',
    label: {
      position: 'top',
      // content: ''
    },
    legend: false,
    columnStyle: {
      cursor: 'pointer',
    },
    theme: {
      colors20: [
        '#0F1C73',
        '#1A449F',
        '#2B67BA',
        '#3C88C0',
        '#4BA7B3',
        '#5AC39B',
        '#6ADD7F',
        '#A8DD6A',
        '#DDDD6A',
      ],
    },
    xAxis: {
      label: {
        formatter: (value) => value.slice(0, 3).toUpperCase(),
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
    },
    yAxis: {
      max: 60,
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      }
    },

  };
  return (
    <>
      <StyledTitle>
        Number Of Employees Per Department
      </StyledTitle>
      <StyledChartContainer>

        <Column
          {...config}
        />

      </StyledChartContainer>
    </>
  );
}


export default EmployeesPerDepartment