import {
  USERNAME,
  TOGGLE_DRAWER,
  USER_ROLE,
  USER_PROFILE,
  IS_LOGGED_IN,
  SET_DEPARTMENT_ID,
  SET_SYSTEM_DATE,
  SET_BREAK_TYPES,
  SET_IS_ON_BREAK,
  SET_IS_CHECKED_IN,
  TOGGLE_SUCCESS_MODAL,
  TOGGLE_PROCESSING_MODAL,
  LOADING_DATE_FIRST_TIME,
  SET_DEPARTMENT_LIST,
  SET_ALL_DEPARTMENT_LIST,
  SET_LEAVE_CATEGORY_LIST,
  SET_LOCATIONS,
  SET_PUNCH_ACTIONS,
  SET_ROLE_LIST,
  CLEAR_STORE,
  SET_CHECK_IN_LOCATION_ID,
  SET_USER_LIST,
  SET_STATUS_LIST,
  SET_PUNCH_ID,
  SET_BREAK_ID,
  SET_SHIFT_TIME_MASTER,
  SET_NOTIFICATION_LIST,
  SET_WEB_SOCKET_STATUS,
  SET_CHAT_SELECTED_STAFF_ID,
  SET_CHAT_MESSAGE_LIST,
  SET_CHAT_MESSAGE_QUEUE,
  SET_UNREAD_MESSAGE_INDICATOR,
  PROFILE_PIC,
  CLIENT_ID,
  SET_SUPERVISOR_LIST,
  LEVEL_LIST
} from './actionsTypes';

export const setUsernName = response => (dispatch) => {
  dispatch({
    type: USERNAME,
    payload: response,
  });
};

export const setToggleDrawer = (response) => (dispatch) => {
  dispatch({
    type: TOGGLE_DRAWER,
    payload: response,
  });
};

export const setIsUserLoggedIn = (response) => (dispatch) => {
  dispatch({
    type: IS_LOGGED_IN,
    payload: response,
  });
};

export const setUserRole = (response) => (dispatch) => {
  dispatch({
    type: USER_ROLE,
    payload: response,
  });
};

export const setUserProfile = (response) => (dispatch) => {
  dispatch({
    type: USER_PROFILE,
    payload: response,
  });
}

export const setDepartmentId = (response) => (dispatch) => {
  dispatch({
    type: SET_DEPARTMENT_ID,
    payload: response,
  });
};

export const setSystemDate = (response) => (dispatch) => {
  dispatch({
    type: SET_SYSTEM_DATE,
    payload: response,
  });
};

export const setBreakTypesList = (response) => (dispatch) => {
  dispatch({
    type: SET_BREAK_TYPES,
    payload: response,
  });
};

export const setIsOnBreak = (response) => (dispatch) => {
  dispatch({
    type: SET_IS_ON_BREAK,
    payload: response,
  });
};

export const toggleSuccessModal = (response) => (dispatch) => {
  dispatch({
    type: TOGGLE_SUCCESS_MODAL,
    payload: response,
  });
};

export const toggleProcessingModal = (response) => (dispatch) => {
  dispatch({
    type: TOGGLE_PROCESSING_MODAL,
    payload: response,
  });
};

export const setLoadingDataFirstTime = (response) => (dispatch) => {
  dispatch({
    type: LOADING_DATE_FIRST_TIME,
    payload: response,
  });
};

export const setDepartmentList = (response) => (dispatch) => {
  dispatch({
    type: SET_DEPARTMENT_LIST,
    payload: response,
  });
};

export const setLeaveCategoryList = (response) => (dispatch) => {
  dispatch({
    type: SET_LEAVE_CATEGORY_LIST,
    payload: response,
  });
};

export const setLocationList = (response) => (dispatch) => {
  dispatch({
    type: SET_LOCATIONS,
    payload: response,
  });
};

export const setPunchActions = (response) => (dispatch) => {
  dispatch({
    type: SET_PUNCH_ACTIONS,
    payload: response,
  });
};

export const setRoleList = (response) => (dispatch) => {
  dispatch({
    type: SET_ROLE_LIST,
    payload: response,
  });
};

export const setCheckedIn = (response) => (dispatch) => {
  dispatch({
    type: SET_IS_CHECKED_IN,
    payload: response,
  });
};

export const setCheckInLocationId = (response) => (dispatch) => {
  dispatch({
    type: SET_CHECK_IN_LOCATION_ID,
    payload: response,
  });
};

export const setUserList = (response) => (dispatch) => {
  dispatch({
    type: SET_USER_LIST,
    payload: response,
  });
};

export const setStatusList = (response) => (dispatch) => {
  dispatch({
    type: SET_STATUS_LIST,
    payload: response,
  });
};

export const setAllDepartmentList = (response) => (dispatch) => {
  dispatch({
    type: SET_ALL_DEPARTMENT_LIST,
    payload: response,
  })
}

export const setPunchId = (response) => (dispatch) => {
  dispatch({
    type: SET_PUNCH_ID,
    payload: response,
  })
}

export const setBreakId = (response) => (dispatch) => {
  dispatch({
    type: SET_BREAK_ID,
    payload: response,
  })
}

export const setShiftTimeMaster = (response) => (dispatch) => {
  dispatch({
    type: SET_SHIFT_TIME_MASTER,
    payload: response,
  })
}

export const setNotificationList = (response) => (dispatch) => {
  dispatch({
    type: SET_NOTIFICATION_LIST,
    payload: response,
  })
}

export const setWebSocketConnectionStatus = (response) => (dispatch) => {
  dispatch({
    type: SET_WEB_SOCKET_STATUS,
    payload: response,
  })
}

export const setChatMessageList = (response) => (dispatch) => {
  dispatch({
    type: SET_CHAT_MESSAGE_LIST,
    payload: response,
  })
}

export const setChatMessageQueue = (response) => (dispatch) => {
  dispatch({
    type: SET_CHAT_MESSAGE_QUEUE,
    payload: response,
  })
}

export const setUnreadMessageIndicator = (response) => (dispatch) => {
  dispatch({
    type: SET_UNREAD_MESSAGE_INDICATOR,
    payload: response,
  })
}

export const setChatSelectedStaffId = (response) => (dispatch) => {
  dispatch({
    type: SET_CHAT_SELECTED_STAFF_ID,
    payload: response,
  })
}

export const setProfilePic = (response) => (dispatch) => {
  dispatch({
    type: PROFILE_PIC,
    payload: response,
  })
}

export const clearStore = () => (dispatch) => {
  dispatch({
    type: CLEAR_STORE,
    payload: '',
  });
};

export const setClientid = (response) => (dispatch) => {
  dispatch({
    type: CLIENT_ID,
    payload: response,
  })
}

export const setLevelList = (response) => (dispatch) => {
  dispatch({
    type: LEVEL_LIST,
    payload: response,
  })
}

export const setSupervisorList = (response) => (dispatch) => {
  dispatch({
    type: SET_SUPERVISOR_LIST,
    payload: response,
  });
};