import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import QualificationByGender from './QualificationByGender';
import QualificationByTenure from './QualificationByTenure';
import QualificationByAge from './QualificationByAge';
import QualificationByGradecode from './QualificationByGradecode';
import Filters from 'molecules/Filters';
import { StyledDiv } from 'pages/PeopleDashboard/PeopleDashboard';
import moment from 'moment';
import queryKeys from 'common/queryKey';
import { useFetch } from 'hooks/useFetch';
import { getClientid } from 'redux/selectors';
import { connect } from 'react-redux';

function Qualification({ clientid }) {
  const initialValues = {
    competencyQC: [],
    serviceline: [],
    managingoffice: [],
    employee: '',
    officesupervisor: '',
    date: "",
    end_date: ""
  };

  const [value, setValue] = useState(initialValues);
  const [commonData, setCommonData] = useState({});
  const [headCount, setHeadCount] = useState('');

  console.log('common data-->', commonData);

  const { getQualificationCommonCountData } = queryKeys;

  const payload = {
    method: 'commonCount',
    parameters: {
      startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || ""
    }
  };
  
  const payloadString = JSON.stringify(payload);
  const { key, url } = getQualificationCommonCountData(clientid, payloadString);
  const { data, isLoading, refetch } = useFetch(key, url, { enabled: !!clientid }, payload);

  useEffect(() => {
    if (data?.response?.qualifications) {
      const dataList = data.response.qualifications;
      const validQualifications = ['Master', 'Doctorate', 'Certificate', 'Diploma', 'Bachelor'];
  
      const qualificationCounts = dataList.reduce((counts, item) => {
        if(item?.educationdetails?.length > 0){
          item.educationdetails.forEach(detail => {
            if (validQualifications.includes(detail.awarded)) {
              counts[detail.awarded] = (counts[detail.awarded] || 0) + 1;
            }
          });
        }
        
        return counts;
      }, {});
      setCommonData(qualificationCounts);
      setHeadCount(data?.response?.headCount || 0);
    }
  }, [data]);

  return (
    <div>
      <StyledDiv>
        <b className="pb-2">
          <u>Qualification</u>
        </b>
        <Filters value={value} setValue={setValue} />
        <div className="mainbox">
          <div className="maincontent">
            <span>{headCount || 0}</span>
            <b>HeadCount</b>
          </div>
          <div className="maincontent">
            <span>{commonData.Bachelor || 0}</span>
            <b>Bachelor Degree</b>
          </div>
          <div className="maincontent">
            <span>{commonData.Master || 0}</span>
            <b>Master Degree</b>
          </div>
          <div className="maincontent">
            <span>{commonData.Diploma || 0}</span>
            <b>Diploma</b>
          </div>
          <div className="maincontent">
            <span>{commonData.Certificate || 0}</span>
            <b>Certificate</b>
          </div>
          <div className="maincontent">
            <span>{commonData.Doctorate || 0}</span>
            <b>Doctorate</b>
          </div>
        </div>
      </StyledDiv>
      <Row gutter={16}>
        <Col md={24} sm={24} xs={24} xl={12}>
          <QualificationByAge value={value} />
        </Col>
        <Col md={24} sm={24} xs={24} xl={12}>
          <QualificationByGender value={value} />
        </Col>
        <Col md={24} sm={24} xs={24} xl={12}>
          <QualificationByTenure value={value} />
        </Col>
        <Col md={24} sm={24} xs={24} xl={12}>
          <QualificationByGradecode value={value} />
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = state => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(Qualification);
