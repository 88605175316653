import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';

// Redux
import { connect } from 'react-redux';
import { getUserName, getUserRoleId, getClientid } from 'redux/selectors';
import styled from '@emotion/styled';
import EmployeesPerDepartment from './EmployeesPerDepartment';
import EmployeesPerRole from './EmployeesPerRole';
import EmployeesPerLocation from '../pages/PeopleDashboard/EmployeesPerLocation';
import RecentEmployeeList from './RecentEmployeeList';
import OverTimeAnalysis from './OverTimeAnalysis';
import EmployeeAttendanceAnalysis from './EmployeeAttendanceAnalysis';
import { CALL_API } from 'common/API';
import moment from 'moment';

const StyledRow = styled(Row)`
  padding-top: 20px;
`;

function NonStaffDashboard({ userRoleId, clientid }) {
  const [employeeDept, setEmployeeDept] = useState([])
  const [employeeRole, setEmployeeRole] = useState([])
  const [employeeLoc, setEmployeeLoc] = useState([])
  const [ displayReportLoader, toggleReportLoader ] = useState(false);

  const getData = async()=>{
        toggleReportLoader(true)
        const { 
          numberOfUsersPerDepartmentData,
          numberOfUsersPerLocationData,
          numberOfUsersPerRoleData
         } = await CALL_API(`mgview-dashboard/${clientid}`, 'post', {
          method: 'numberOfUsersPerCategory',
          "parameters": {
            "year": moment(new Date).format('yyyy')
        }
        });
        setEmployeeDept(numberOfUsersPerDepartmentData)
        setEmployeeLoc(numberOfUsersPerLocationData)
        setEmployeeRole(numberOfUsersPerRoleData)
        toggleReportLoader(false)
  
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <StyledRow gutter={16}>

         <>
         {/* admin dashBoard starts here*/}

         <Col md={24} sm={24} xs={24} xl={12}>
            <OverTimeAnalysis/>
          </Col>
          
          <Col md={24} sm={24} xs={24} xl={12}>
           <EmployeeAttendanceAnalysis/>
          </Col>
       
          <Col md={24} sm={24} xs={24} xl={12}>
            <Spin spinning={displayReportLoader}>
              <EmployeesPerDepartment report={employeeDept} />  
            </Spin>
          </Col>
          <Col md={24} sm={24} xs={24} xl={12}>
            <Spin spinning={displayReportLoader}>
              <EmployeesPerLocation  report={employeeLoc}/>
            </Spin>
          </Col>
          <Col md={24} sm={24} xs={24} xl={14}>
            <Spin spinning={displayReportLoader}>
              <EmployeesPerRole  report={employeeRole} />
            </Spin>
          </Col>
          <Col md={24} sm={24} xs={24} xl={10}>
            <RecentEmployeeList/>
          </Col> 
        </>
   
        
    </StyledRow>
  );
}

const mapStateToProps = state => ({
  userName: getUserName(state),
  userRoleId: getUserRoleId(state),
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(NonStaffDashboard);
