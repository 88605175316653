import React, { useEffect, useState } from "react";
import { Bar } from "@ant-design/plots";
import queryKeys from "common/queryKey";
import moment from "moment";
import { getClientid } from "redux/selectors";
import { connect } from "react-redux";
import { useFetch } from "hooks/useFetch";
import { Spin } from "antd";
import DetailsModal from "organisms/DetailsModal";

const initialData = [
  {
    xValue: "01-P5 & P4",
    name: "Bachelor",
    listItem: ["P5", "P4"],
    Count: 0,
  },
  {
    xValue: "02-P1, P2 & P3",
    name: "Bachelor",
    listItem: ["P1", "P2", "P3"],
    Count: 0,
  },
  {
    xValue: "03-C3, C4, M1 & M2",
    name: "Bachelor",
    listItem: ["C3", "C4", "M1", "M2"],
    Count: 0,
  },
  {
    xValue: "04-B1, B2, C1 & C2",
    name: "Bachelor",
    listItem: ["B1", "B2", "C1", "C2"],
    Count: 0,
  },
  {
    xValue: "05-A1 & A2",
    name: "Bachelor",
    listItem: ["A1", "A2"],
    Count: 0,
  },
  {
    xValue: "06-S1,S2,S3 & S4",
    name: "Bachelor",
    listItem: ["S1", "S2", "S3", "S4", "S5"],
    Count: 0,
  },
];

function AttritionByGradeCode({ value, clientid }) {
  const [dataList, setDataList] = useState(initialData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [plotData, setPlotData] = useState("");


  const { getAttritionByGradeCode } = queryKeys;

  const payload = {
    method: "attritionByGradeCode",
    parameters: {
      startDate: value?.date ? moment(value.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date
        ? moment(value.end_date).format("YYYY-MM-DD")
        : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || "",
    },
  };

  const payloadString = JSON.stringify(payload);
  const { key, url } = getAttritionByGradeCode(clientid, payloadString);

  const { data, isLoading, refetch } = useFetch(
    key,
    url,
    {
      enabled: !!clientid,
    },
    payload
  );

  useEffect(() => {
    const response = data?.response?.attritionByGradeCode;
    if (response) {
      const updatedDataList = initialData.map((dataItem) => {
        const newDataItem = { ...dataItem };
        newDataItem.Count = response.filter((item) =>
          dataItem.listItem.includes(item.grade)
        ).length;
        return newDataItem;
      });
      setDataList(updatedDataList);
    }
  }, [data]);

  const plotClick = () =>{
    if (plotData?.data?.data) {
      const filterData = data?.response?.attritionByGradeCode?.filter(
        (item) => plotData.data.data.listItem?.includes(item?.grade)
      );
      setTableData(filterData);
      setIsModalVisible(true);
    }
}

useEffect(()=>{
    if(plotData !== ""){
    plotClick();
    }
},[plotData])

  const config = {
    data: dataList,
    xField: "Count",
    yField: "xValue",
    animation: false,
    color: "#9CD3FA",
    onReady: (plot) => {
      plot.on("plot:click", (evt) => {
        setPlotData(evt);
      });
    },
    label: {
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    xAxis: {
      tickInterval: 3,
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };

  return (
    <>
      <h6 className="mt-2">Attrition By Grade Code:</h6>
      <div className="box-container">
        <Spin spinning={isLoading}>
          <Bar {...config} />
        </Spin>
      </div>
      <DetailsModal
        isopen={isModalVisible}
        isCancel={() => setIsModalVisible(false)}
        tabledata={tableData}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(AttritionByGradeCode);
