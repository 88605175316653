import React from 'react';
import { Row, Col } from 'antd';
import { Pie } from '@ant-design/plots';
import styled from '@emotion/styled';
import { last, map, get, first } from 'lodash';
import moment from 'moment';

const StyledChartContainer = styled.div`
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 4px 27px 0px #00000017;
  .styled-circle {
    height: 30px;
    width: 30px;
    svg {
      margin: 4px;
    }
  }
  .css-1ps7utq {
    margin-bottom: 0 !important;
  }
  .css-1180qxj{
    margin-bottom: 0 !important;
  }
  .css-1ig93pz{
    margin-bottom: 0 !important;
  }
  .report-title {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 0.8rem;
    padding-top: 3px;
    padding-left:10px;
    text-transform: uppercase;
  }
  .report-title1 {
    font-weight: bold;
    font-size: 0.7rem;
    padding-top: 3px;
  }
  .legends {
    padding-top: 2rem;
    padding-left: 5px;
    border-radius: 5px;
    text-align: left;
    div {
      padding-bottom: 5px;
    }
  }
  .leave_tracker_header{
    display: flex;
    justify-content: space-between;
  }
  .leave_header_text{
  font-family: 'DM Sans', sans-serif; 
  font-size: 14px; 
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em; 
  text-align: left;
  color: #1F243B; 
  }
`;

const StyledColorBox = styled.span`
  ${({ color }) => color && `background: ${color};`}
  border-radius: 4px;
  padding: 0px 8px;
  margin-right: 10px;
`;
function LeaveTrackerChart({
    iconName,
    title,
    data,
    staffLeave
}) {
    const totalLeaveAvailable = get(first(data), 'value', 0);
    const totalLeaveConsumed = get(last(data), 'value', 0);
    const leaveAvailable = totalLeaveAvailable - totalLeaveConsumed;
    const config = {
        width: 120,
        height: 120,
        appendPadding: 6,
        data,
        angleField: 'value',
        colorField: 'type',
        color: map(data, 'color', []),
        radius: 1,
        innerRadius: 0.8,
        tooltip: false,
        legend: false,
        label: false,
        statistic: {
            title: false,
            content: {
                style: {
                    fontSize: 13
                },
                content: `${get(last(data), 'value', '')}/${leaveAvailable}`,
            },
        },
    };
    
    return (
        <StyledChartContainer>
            <Row>
                <Col span={20} className='report-title'>
                    <div className='leave_tracker_header'>
                        <p className='leave_header_text'>{title} - {moment(new Date()).format("yyyy")}</p>
                    </div>
                </Col>
                <Col span={12}>
                    <Pie {...config} />
                </Col>
                <Col span={12} className='legends'>
                    <div>
                        <StyledColorBox color={get(last(data), 'color')}></StyledColorBox>
                        <span>
                            {get(last(data), 'type', '')} : {data[1].value == null ? "null" : get(last(data), 'value', '')}
                        </span>
                    </div>
                    <div>
                        <StyledColorBox color={get(first(data), 'color')}></StyledColorBox>
                        <span>
                            {get(first(data), 'type', '')} : {data[0].value == null ? "null" : leaveAvailable}
                        </span>
                    </div>
                    {(staffLeave == "Annual Leave" || staffLeave == "Department's Annual Leave Balance") && (
                        <div className='report-title1'>
                            Last Year Leave Balance : <span style={{ color: 'blue', fontWeight: 'normal' }}>{get(first(data), 'lastYearLeaveBalance', '')}</span>
                        </div>
                    )}
                </Col>
            </Row>
        </StyledChartContainer>
    );
}

export default LeaveTrackerChart;
