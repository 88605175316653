import { Col, Row, Spin } from "antd";
import Filters from "molecules/Filters";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeadcountByAge from "./HeadcountByAge";
import HeadcountByLevel from "./HeadcountByLevel";
import EmployeeHiredByMonth from "./EmployeeHiredByMonth";
import HeadcountByGradeCode from "./HeadcountByGradeCode";
import queryKeys from "common/queryKey";
import moment from "moment";
import { useFetch } from "hooks/useFetch";
import { getClientid } from "redux/selectors";
import { connect } from "react-redux";
import { StyledDiv } from 'pages/PeopleDashboard/PeopleDashboard'

const StyledRow = styled(Row)`
  padding-top: 20px;
`;


function DiversityAndInclusion({ clientid }) {

  const initialValues={
    competencyQC: [],
    serviceline: [],
    managingoffice: [],
    employee: "",
    officesupervisor: "",
    date: "",
    end_date: ""
  }
  const [ commonData, setCommonData] = useState({})
const [value, setValue] = useState(initialValues);

const { getPeopleDashboardCommonCountData } = queryKeys

const payload =  {
  method: "commonCount",
  parameters: {
    startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
    endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
    managingOffice: value?.managingoffice || [],
    competencyQC: value?.competencyQC || [],
    officesupervisor: value?.officesupervisor || "",
    attritionstartDate: value?.date === "" ? moment().subtract(11, "months").startOf("month").format('YYYY-MM-DD') : moment(value.date).subtract(11, "months").startOf("month").format('YYYY-MM-DD'),
    attritionendDate: value?.date === "" ? moment().endOf("month").format('YYYY-MM-DD') : moment(value.date).endOf('month').format('YYYY-MM-DD'),
  }
}
const payloadString = JSON.stringify(payload);
const { key, url } = getPeopleDashboardCommonCountData(clientid, payloadString);
const {
  data,
  isLoading,
  refetch,
} = useFetch(
  key,
  url,
  {
    enabled: !!clientid,
  },
  payload
);

useEffect(() => {
  const dataList = data?.response
  if(dataList){
    setCommonData(dataList);
  }
}, [data]);

  return (
    <StyledDiv>
      <b className="pb-2">
        <u>Diversity & Inclusion</u>
      </b>
      <Filters setValue={setValue} value={value}/>
      <div className="mainbox">
        <div className="maincontent">
          <span>{commonData?.headCount ? commonData?.headCount : 0}</span>
          <b>HeadCount</b>
        </div>
        <div className="maincontent">
          <span>{commonData?.maleCount ? commonData?.maleCount : 0}</span>
          <b>Male</b>
        </div>
        <div className="maincontent">
          <span>{commonData?.femaleCount ? commonData?.femaleCount : 0}</span>
          <b>Female</b>
        </div>
      </div>
      <StyledRow gutter={16}>
        <Col md={24} sm={24} xs={24} xl={12}>
          <HeadcountByAge value={value} />
        </Col>
        <Col md={24} sm={24} xs={24} xl={12}>
          <HeadcountByLevel value={value} />
        </Col>
        <Col md={24} sm={24} xs={24} xl={12}>
          <EmployeeHiredByMonth value={value} />
        </Col>
        <Col md={24} sm={24} xs={24} xl={12}>
          <HeadcountByGradeCode value={value} />
        </Col>
      </StyledRow>
    </StyledDiv>
  );
}

const mapStateToProps = (state) => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(DiversityAndInclusion);
