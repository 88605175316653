import { Col, Row, Spin } from 'antd';
import { CALL_API } from 'common/API';
import Filters from 'molecules/Filters'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getClientid, getUserName, getUserRoleId } from 'redux/selectors';
import styled from 'styled-components'
import GenderDistribution from './GenderDistribution';
import Employeestotalexperience from './Employeestotalexperience';
import HireandAttritionTrendbyMonth from './HireandAttritionTrendbyMonth';
import EmployeesPerLocation from './EmployeesPerLocation';
import queryKeys from 'common/queryKey';
import { useFetch } from 'hooks/useFetch';

export const StyledDiv = styled.div`
  .mainbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: center;
    height: 100px;
    border: 1px solid #D6D6D6;
    padding: 10px 0px;
    box-shadow: 0px 3px 6px #0000000B;
    border-radius: 5px;
    @media(max-width: 768px){
      padding:0;
      height: 100%;
      flex-wrap:wrap;
      justify-content: start;
      border: none;
      box-shadow: none;
    }
    
    /* border: 1px solid #D6D6D6; */
  }
  .maincontent {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    border-right: 1px solid #D6D6D6;
    flex-direction: column;
    justify-content: center;
    :last-child {
    border-right: none;
}
    /* Remove border-radius from here */
    span{
      font-size: 32px;
      font-weight: bold;
    }
    @media(max-width: 768px){
      width: 25%;
      border: 1px solid #D6D6D6;
      box-shadow: 0px 3px 6px #0000000B;
      :last-child {
    border-right: 1px solid #D6D6D6;
     }
    }
    @media(max-width: 513px){
      width: 50%;
      font-size: 12px;
      span{
      font-size: 16px;
      font-weight: bold;
    }
    }
  }
  .mainbox, .maincontent {
    /* border: 1px solid #D6D6D6; */
  }
`;

function PeopleDashboard({clientid}) {

  const StyledRow = styled(Row)`
  padding-top: 20px;
`;
const initialValues={
  competencyQC: [],
  serviceline: [],
  managingoffice: [],
  employee: "",
  officesupervisor: "",
  date: "",
  end_date: "",
}
const [ commonData, setCommonData] = useState({})
const [value, setValue] = useState(initialValues);


const { getPeopleDashboardCommonCountData } = queryKeys

const payload = {
  method: "commonCount",
  parameters: {
    startDate: value?.date ? moment(value.date).format("YYYY-MM-DD") : "",
    endDate: value?.end_date ? moment(value.end_date).format("YYYY-MM-DD") : "",
    managingOffice: value?.managingoffice || [],
    competencyQC: value?.competencyQC || [],
    officesupervisor: value?.officesupervisor || "",
    attritionstartDate: value?.date === "" ? moment().subtract(11, "months").startOf("month").format('YYYY-MM-DD') : moment(value.date).subtract(11, "months").startOf("month").format('YYYY-MM-DD'),
    attritionendDate: value?.date === "" ? moment().endOf("month").format('YYYY-MM-DD') : moment(value.date).endOf('month').format('YYYY-MM-DD'),
  }
};

const payloadString = JSON.stringify(payload);
const { key, url } = getPeopleDashboardCommonCountData(clientid, payloadString);
const {
  data,
  isLoading,
  refetch,
} = useFetch(
  key,
  url,
  {
    enabled: !!clientid,
  },
  payload
);

useEffect(() => {
  const dataList = data?.response
  if(dataList){
    setCommonData(dataList);
  }
}, [data]);


  return (
    <StyledDiv>
      <b className='pb-2'><u>People Dashboard</u></b>
        <Filters setValue={setValue} value={value}/>
    <div className='mainbox'>
      <div className='maincontent'>
        <span>{commonData?.headCount ? commonData?.headCount : 0}</span>
        <b>HeadCount</b>
      </div>
      <div className='maincontent'>
        <span>{commonData?.maleCount ? commonData?.maleCount : 0}</span>
        <b>Male</b>
      </div>
      <div className='maincontent'>
        <span>{commonData?.femaleCount ? commonData?.femaleCount : 0}</span>
        <b>Female</b>
      </div>
      <div className='maincontent'>
        <span>{commonData?.hireCount ? commonData?.hireCount : 0}</span>
        <b>Hiring Count</b>
      </div>
      <div className='maincontent'>
        <span>{commonData?.hireCount ? ((commonData?.hireCount / commonData?.headCount) * 100).toFixed(1) : "0"}%</span>
        <b>Hiring Rate</b>
      </div>
      <div className='maincontent'>
        <span>{commonData?.attritionCount ? commonData?.attritionCount : 0}</span>
        <b>Attrition Count</b>
      </div>
      <div className='maincontent'>
        <span>{commonData?.attritionCount ? ((commonData?.attritionCount / commonData?.headCount) * 100).toFixed(1) : "0"}%</span>
        <b>Attrition Rate</b>
      </div>
    </div>
        <StyledRow gutter={16}>

         <>
          <Col md={24} sm={24} xs={24} xl={12}>
              <GenderDistribution  value={value}/>
          </Col>
          <Col md={24} sm={24} xs={24} xl={12}>
              <EmployeesPerLocation value={value}/>
          </Col>
          <Col md={24} sm={24} xs={24} xl={24}>
              <Employeestotalexperience value={value}/>
          </Col>
          <Col md={24} sm={24} xs={24} xl={24}>
              <HireandAttritionTrendbyMonth value={value}/>
          </Col>
        </>
   
        
    </StyledRow>
    </StyledDiv>
  )
}
const mapStateToProps = state => ({
  userName: getUserName(state),
  userRoleId: getUserRoleId(state),
  clientid: getClientid(state),
});

export default connect(mapStateToProps,null)(PeopleDashboard)