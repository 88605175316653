import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import queryKeys from "common/queryKey";
import moment from "moment";
import { useFetch } from "hooks/useFetch";
import { connect } from "react-redux";
import { getClientid, getSupervisorList } from "redux/selectors";
import { filter, forEach, isEmpty, keyBy, map, reduce, uniqBy } from "lodash";
import { Spin } from "antd";
import DetailsModal from "organisms/DetailsModal";

function AttritionbyManager({ value, clientid, supervisorList }) {
  const [dataList, setDataList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [plotData, setPlotData] = useState("");

  const { getAttritionByManager } = queryKeys;

  const payload = {
    method: "attritionByOfficeSupervisor",
    parameters: {
      startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date
        ? moment(value?.end_date).format("YYYY-MM-DD")
        : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || "",
    },
  };

  const payloadString = JSON.stringify(payload);
  const { key, url } = getAttritionByManager(clientid, payloadString);

  const { data, isLoading, refetch } = useFetch(
    key,
    url,
    {
      enabled: !!clientid,
    },
    payload
  );

  useEffect(() => {
    const response = data?.response?.attritionByOfficeSupervisor;
    if (!isEmpty(response)) {
      dataTranformation(response);
    }
  }, [data]);

  function dataTranformation(attritionsList) {
   
    // Step 5: Create resultant array
    const OthersList = filter(attritionsList, val => val.officesupervisor === "Other");
    let otherObject = {
        officesupervisor: "Other",
        Count: OthersList?.length,
        Users: OthersList,
    }
    const result = map(supervisorList, (supervisor) => {
      const users = filter(attritionsList, val => val.officeSupervisorId === supervisor.employeeId);
      return {
        officesupervisor: supervisor.name,
        Count: users?.length,
        Users: users,
      };
    });
    result.push(otherObject)


    setDataList(result);
  }

  const plotClick = () => {
    if (plotData?.data?.data) {
      setTableData(plotData?.data?.data?.Users);
      setIsModalVisible(true);
    }
  };

  useEffect(() => {
    if (plotData !== "") {
      plotClick();
    }
  }, [plotData]);

  const config = {
    data: dataList,
    xField: "officesupervisor",
    yField: "Count",
    color: "#9CD3FA",
    animation: false,
    onReady: (plot) => {
      plot.on("plot:click", (evt) => {
        setPlotData(evt);
      });
    },
    label: {
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
      },
    },
    yAxis: {
      tickInterval: 5,
      title: {
        text: "Head Count",
        style: {
          fontSize: 12,
          fontWeight: 500,
        },
      },
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };

  return (
    <>
      <h6 className="mt-2">Attrition by Office Supervisor:</h6>
      <div className="box-container">
        <Spin spinning={isLoading}>
          <Column {...config} />
        </Spin>
      </div>
      <DetailsModal
        isopen={isModalVisible}
        isCancel={() => setIsModalVisible(false)}
        tabledata={tableData}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  clientid: getClientid(state),
  supervisorList: getSupervisorList(state),
});

export default connect(mapStateToProps, null)(AttritionbyManager);
