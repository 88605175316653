import React from "react";
import { Line } from "@ant-design/plots";
import styled from "@emotion/styled";
import { find } from "lodash";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function YearwiseTotalHoursOvertime({ report, startYear, endYear }) {

  console.log("report --->", report)
  const config = {
    data: report,
    height: 300,
    xField: "Year",
    yField: "value",
    // seriesField: "Year",
    label: {
      position: "middle",
      content: "",
    },
    tooltip: {
      title: '',
      formatter: (datum) => {
    
        const monthData = find(report, { 'Year': datum?.Year});
        return {
          name: 'Hours',
          value:monthData?.Hours,
        };
      },
    },
    columnStyle: {
      cursor: "pointer",
    },
    legend: false,
    yAxis: {
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
    theme: {
      colors10: [
        "#2B67BA",
        "#3C88C0",
        "#4BA7B3",
        "#5AC39B",
        "#6ADD7F",
        "#A8DD6A",
        "#DDDD6A",
      ],
    },
  };
  return (
    <>
      <StyledTitle>Total Hours Of Overtime</StyledTitle>
      <StyledChartContainer>
        <Line {...config} />
      </StyledChartContainer>
    </>
  );
}

export default YearwiseTotalHoursOvertime;
