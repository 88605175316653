import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import moment from "moment";
import { connect } from "react-redux";
import { getClientid } from "redux/selectors";
import { Spin } from "antd";
import DetailsModal from "organisms/DetailsModal";

const EmployeeHiredByMonth = ({ value, clientid }) => {
  const [employeeHiredByMonthData, setEmployeeHiredByMonthData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [plotData, setPlotData] = useState("");

  const { getEmployeeHiredByMonthData } = queryKeys;

  const startDate = value?.date
    ? moment(value?.date).startOf("month")
    : moment().startOf("month").clone().subtract(12, "months").startOf("month");
  const endDate = startDate.clone().add(12, "months").endOf("month");

  const payload = {
    method: "employeeHiredByMonth",
    parameters: {
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || "",
    },
  };

  const payloadString = JSON.stringify(payload);
  const { key, url } = getEmployeeHiredByMonthData(clientid, payloadString);

  const { data, isLoading } = useFetch(
    key,
    url,
    {
      enabled: !!clientid,
    },
    payload
  );

  useEffect(() => {
    const dataList = data?.response?.employeeHiredByMonth;
    if (dataList) {
      setEmployeeHiredByMonthData(dataList);
    }
  }, [data]);

  const months = [];
  for (
    let date = startDate.clone();
    date.isBefore(endDate) || date.isSame(endDate, "month");
    date.add(1, "month")
  ) {
    months.push({
      month: date.format("MMM YYYY"),
      fullMonth: date.format("MMMM"),
      year: date.year(),
    });
  }

  const mergeData = (data) => {
    return months.map((month) => {
      const hireData = data?.filter(
        (item) => item.month === month.fullMonth && item.year === month.year
      );
      return {
        month: month.month,
        hireCount: hireData ? hireData?.length : 0,
      };
    });
  };

  const finalData = mergeData(employeeHiredByMonthData);

  const plotClick = () => {
    if(plotData?.data?.data){
    const parsedDate = moment(plotData?.data?.data?.month, "MMM YYYY");
    const month = parsedDate.format("MMMM"); // Full month name, e.g., "June"
    const year = parsedDate.format("YYYY"); // Year, e.g., "2024"

    // Filter the employee data based on the parsed month and year
    const filterData = data?.response?.employeeHiredByMonth?.filter(
      (item) => item?.month === month && item?.month === month
    );

    setTableData(filterData);
    setIsModalVisible(true);
  }
  };

  useEffect(() => {
    if (plotData !== "") {
      plotClick();
    }
  }, [plotData]);

  const config = {
    data: finalData,
    xField: "month",
    yField: "hireCount",
    color: "#9CD3FA",
    animation: false,
    onReady: (plot) => {
      plot.on("plot:click", (evt) => {
        setPlotData(evt);
      });
    },
    label: {
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    xAxis: {
      title: {
        text: "Month",
        style: {
          fontSize: 12,
          fontWeight: 500,
        },
      },
      label: {
        autoHide: true,
        autoRotate: true,
        style: {
          fontSize: 12,
          fill: "#666",
        },
      },
    },
    yAxis: {
      title: {
        text: "Head Count",
        style: {
          fontSize: 12,
          fontWeight: 500,
        },
      },
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };

  return (
    <>
      <h6 className="mt-2">Employee Hired by Month:</h6>
      <Spin spinning={isLoading}>
        <div className="box-container">
          <Column {...config} />
        </div>
      </Spin>
      <DetailsModal
        isopen={isModalVisible}
        isCancel={() => setIsModalVisible(false)}
        tabledata={tableData}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(EmployeeHiredByMonth);
