import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import {
  getAllDepartmentList,
} from 'redux/selectors';
import { find } from 'lodash';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function ProductivityOvertime({
  allDepartmentList, data
}) {
  const [displayReportLoader, toggleReportLoader] = useState(false);
  console.log("allDepartmentList  ->", allDepartmentList)
  console.log("data -->", data)

  const overtimeMap = new Map(data.map(item => [item.department_id, item]));

  function convertTimeToDecimal(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours + minutes / 100; // Convert minutes to decimal by dividing by 100
}

// Merge arrays based on department_id
const mergedArray = allDepartmentList.map(department => {
    const overtimeObject = overtimeMap.get(department.department_id);
    const overtime = overtimeObject ? overtimeObject.overtime : "00:00:00";
    const value = convertTimeToDecimal(overtime)
    return {
        ...department,
        overtime,
        value
    };
});

console.log("mergedArray -->", mergedArray)

  const modifiedData = data.map(item => ({
    ...item,
    overtime: parseInt(item.overtime.split(':')[0]),
  }));


  const config = {
    padding: 'auto',
    data: mergedArray,
    height: 300,
    xField: 'department_name',
    yField: 'value',
    seriesField: 'department_name',
    label: {
      position: 'top',
      // content: ''
    },
    legend: false,
    columnStyle: {
      cursor: 'pointer',
    },
    tooltip: {
      title: '',
      formatter: (datum) => {
    
        const monthData = find(mergedArray, { 'department_name': datum?.department_name });
        return {
          name: 'Hours',
          value:monthData?.overtime.replace(/:\d{2}$/, ''),
        };
      },
    },
    theme: {
      colors20: [
        '#0F1C73',
        '#1A449F',
        '#2B67BA',
        '#3C88C0',
        '#4BA7B3',
        '#5AC39B',
        '#6ADD7F',
        '#A8DD6A',
        '#DDDD6A',
      ],
    },
    xAxis: {
      label: {
        formatter: (value) => value.slice(0, 3).toUpperCase(),
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
    },
    yAxis: {
      max: 60,
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      }
    },

  };
  // const config = {
  //   data: data,
  //   height: 300,
  //   xField: 'department_name',
  //   yField: 'department_id',
  //   seriesField: 'department_name',
  //   label: {
  //     position: 'top',
  //   },
  //   columnStyle: {
  //     fill: 'blue', // Set fill color to blue
  //     cursor: 'pointer',
  //     color: 'blue', // Set color to blue
  //   },
  //   legend: false,
  //   yAxis: {
  //     grid: {
  //       line: {
  //         style: {
  //           opacity: 0
  //         }
  //       }
  //     }
  //   },
  //   xAxis: {
  //     label: {
  //       formatter: (value) => value.slice(0, 3).toUpperCase(),
  //       offset: 10,
  //       autoHide: false,
  //       autoRotate: true,
  //     },
  //   },
  // };
  return (
    <>      
      <StyledTitle>
         Over time: Department to hours ratio
      </StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayReportLoader}>
          <Column {...config} />
        </Spin>
      </StyledChartContainer>
    </>
  );
}

const mapStateToProps = state => ({
  allDepartmentList: getAllDepartmentList(state),
});

export default connect(mapStateToProps)(ProductivityOvertime);
