import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import { chain, filter, find, includes, isEmpty, map } from "lodash";
import queryKeys from "common/queryKey";
import moment from "moment";
import { useFetch } from "hooks/useFetch";
import { connect } from "react-redux";
import { getClientid, getlevelList } from "redux/selectors";
import { Spin } from "antd";
import DetailsModal from "organisms/DetailsModal";

function AttritionbyDepartment({ value, clientid, levelList }) {
  const [dataList, setDataList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [plotData, setPlotData] = useState("");
  const [levelData, setLevelData] = useState(getLevelData())


  function getLevelData (){
    let level5_values = [];
      levelList.forEach((level) => {
         if (level.levelName === "Level5") {
          level.value.forEach((value) => {
            level5_values.push(...value.value);
          });
        }
      });
    return level5_values
  }
 

  const { getAttritionByDepartment } = queryKeys;

  const payload = {
    method: "attritionByDepartment",
    parameters: {
      startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date
        ? moment(value?.end_date).format("YYYY-MM-DD")
        : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || "",
    },
  };

  const payloadString = JSON.stringify(payload);
  const { key, url } = getAttritionByDepartment(clientid, payloadString);

  const { data, isLoading, refetch } = useFetch(
    key,
    url,
    {
      enabled: !!clientid,
    },
    payload
  );

  useEffect(() => {
    const response = data?.response?.attritionByDepartment;
    if (!isEmpty(response)) {
      dataTranformation(response);
    }
  }, [data]);

  function dataTranformation(attritionUser) {
    // // Extract all unique competencies
    // const uniqueCompetencies = chain(mainArray)
    //   .flatMap("leveldepartmentname")
    //   .uniq()
    //   .value();
  
    // Count matches for each competencyQC and gather the list of user IDs
    // const result = uniqueCompetencies.map((competency) => {
    //   const users = chain(attritionUser)
    //     .flatMap((user) => {
    //       const match = find(mainArray, { userid: user.userid });
    //       return match && includes(match.leveldepartmentname, competency)
    //         ? [user]
    //         : [];
    //     })
    //     .value();
  
    //   return { Department: competency, Count: users.length, list: users };
    // });

    const result = map(levelData, (level) => {
      const users = filter(attritionUser, val => val.leveldepartmentname.includes(level));
      return {
        Department: level,
        Count: users?.length,
        list: users,
      };
    });
  
    setDataList(result);
  }
  

  const plotClick = () =>{
    if (plotData?.data?.data) {
      // const filterData =
      //   data?.response?.attritionByDepartment?.attritionUsers?.filter(
      //     (item) => item?.competencyQC?.includes(plotData.data.data.competencyQC)
      //   );
      setTableData(plotData?.data?.data?.list);
      setIsModalVisible(true);
    }
}

useEffect(()=>{
    if(plotData !== ""){
    plotClick();
    }
},[plotData])

  const config = {
    data: dataList,
    xField: "Department",
    yField: "Count",
    color: "#9CD3FA",
    animation: false,
    onReady: (plot) => {
      plot.on("plot:click", (evt) => {
       setPlotData(evt);
      });
    },
    label: {
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    xAxis: {
      label: {
        // formatter: (value) => value.slice(0, 3),
        // offset: 10,
        autoHide: true,
        autoRotate: true,
      },
    },
    yAxis: {
      tickInterval: 4,
      title: {
        text: "Head Count",
        style: {
          fontSize: 12,
          fontWeight: 500,
        },
      },
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
  };
  return (
    <>
      <h6 className="mt-2">Attrition by Department:</h6>
      <div className="box-container">
        <Spin spinning={isLoading}>
          <Column {...config} />
        </Spin>
      </div>
      <DetailsModal
        isopen={isModalVisible}
        isCancel={() => setIsModalVisible(false)}
        tabledata={tableData}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  clientid: getClientid(state),
  levelList: getlevelList(state),
});

export default connect(mapStateToProps, null)(AttritionbyDepartment);
