import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Table, Spin } from "antd";
import { getClientid } from "redux/selectors";
import COLORS from "common/Colors";
import { CALL_API } from "common/API";
import { connect } from "react-redux";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  margin-bottom: 25px;
  height: 320px;
  // overflow-y: auto;
`;

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 10px;
    font-size: 0.8rem;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height: 315px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
`;

function RecentEmployeeList({ clientid }) {
  const [employeeList, setEmployeeList] = useState([]);
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const columns = [
    {
      title: "Employee ID",
      dataIndex: "userid",
      key: "userid",
      width: "10%",
      // align: 'center'
    },
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      width: "15%",
      // align: 'center'
    },
    {
      title: "Client ID",
      dataIndex: "clientid",
      key: "clientid",
      width: "15%",
      // align: 'center',
    },
  ];

  const getData = async () => {
    toggleReportLoader(true);
    const { recentlyAddUsersData } = await CALL_API(
      `mgview-dashboard/${clientid}`,
      "post",
      {
        method: "recentlyAddUsers",
      }
    );
    setEmployeeList(recentlyAddUsersData);
    toggleReportLoader(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <StyledTitle>Recent Employees</StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayReportLoader}>
          <StyledTable
            dataSource={employeeList}
            columns={columns}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
            rowKey="employeeID"
            pagination={false}
          />
        </Spin>
      </StyledChartContainer>
    </>
  );
}
const mapStateToProps = (state) => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(RecentEmployeeList);
