import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import queryKeys from 'common/queryKey';
import moment from 'moment';
import { useFetch } from 'hooks/useFetch';
import { connect } from 'react-redux';
import { getClientid } from 'redux/selectors';
import { Spin } from 'antd';
import DetailsModal from 'organisms/DetailsModal';

function HeadcountByAge({ value, clientid }) {
    const initialData = [
        { range: "20-30", gender: "Male", count: 0 },
        { range: "20-30", gender: "Female", count: 0 },
        { range: "20-30", gender: "They / Them", count: 0 },
        { range: "30-40", gender: "Male", count: 0 },
        { range: "30-40", gender: "Female", count: 0 },
        { range: "30-40", gender: "They / Them", count: 0 },
        { range: "40-50", gender: "Male", count: 0 },
        { range: "40-50", gender: "Female", count: 0 },
        { range: "40-50", gender: "They / Them", count: 0 },
        { range: "50-60", gender: "Male", count: 0 },
        { range: "50-60", gender: "Female", count: 0 },
        { range: "50-60", gender: "They / Them", count: 0 },
        { range: "60-70", gender: "Male", count: 0 },
        { range: "60-70", gender: "Female", count: 0 },
        { range: "60-70", gender: "They / Them", count: 0 },
    ];

    const [headCountByAgeData, setHeadCountByAgeData] = useState(initialData);
    const [plotData, setPlotData] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableData, setTableData] = useState([]);

    const { getHeadCountByAgeData } = queryKeys;

    const payload = {
        method: "headCountByAge",
        parameters: {
            startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
            endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
            managingOffice: value?.managingoffice || [],
            competencyQC: value?.competencyQC || [],
            officesupervisor: value?.officesupervisor || "",
        }
    };

    const payloadString = JSON.stringify(payload);
    const { key, url } = getHeadCountByAgeData(clientid, payloadString);

    const { data, isLoading, refetch } = useFetch(key, url, {
        enabled: !!clientid,
    }, payload);

    useEffect(() => {
        const dataList = data?.response?.headCountByAge;
        if (dataList) {
            const updatedData = initialData?.map(item => ({ ...item }));
            dataList.forEach(person => {
                const range = person?.range;
                const gender = person?.gender === "male" ? "Male" : person?.gender === "female" ? "Female" : "They / Them";

                const item = updatedData.find(item => item?.range === range && item?.gender === gender);
                if (item) {
                    item.count += 1;
                }
            });
            setHeadCountByAgeData(updatedData);
        }
    }, [data]);

    useEffect(() => {
        refetch();
    }, [clientid, value]);

    const plotClick = () =>{
        if(plotData?.data?.data){
            const filterData = data?.response?.headCountByAge?.filter(
                item => item?.range === plotData?.data?.data?.range && (
                    (plotData?.data?.data?.gender === "Male" && item?.gender === "male") ||
                    (plotData?.data?.data?.gender === "Female" && item?.gender === "female") ||
                    (plotData?.data?.data?.gender === "They / Them" && item?.gender === "They/ Them")
                )
            );
            setTableData(filterData);
            setIsModalVisible(true);
        }
    }

    useEffect(()=>{
        if(plotData !== ""){
        plotClick();
        }
    },[plotData])

    const config = {
        data: headCountByAgeData,
        isGroup: true,
        xField: 'range',
        yField: 'count',
        seriesField: 'gender',
        color: ['#9CD3FA', '#FAC2F8', '#A39BFF'],
        animation: false,
        label: {
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' }
            ],
        },
        yAxis: {
            title: {
                text: 'Head Count',
                style: {
                    fontSize: 12,
                    fontWeight: 500
                },
            },
            grid: {
                line: {
                    style: {
                        opacity: 0
                    }
                }
            }
        },
        onReady: (plot) => {
            plot.on('plot:click', (evt) => {
               setPlotData(evt)
            });
        },
    };

    return (
        <>
            <h6 className='mt-2'>Headcount by Age:</h6>
            <Spin spinning={isLoading}>
                <div className='box-container'>
                    <Column {...config} />
                </div>
            </Spin>
            <DetailsModal
                isopen={isModalVisible}
                isCancel={() => setIsModalVisible(false)}
                tabledata={tableData}
            />
        </>
    );
}

const mapStateToProps = state => ({
    clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(HeadcountByAge);
