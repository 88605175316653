import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import { useFetch } from 'hooks/useFetch';
import { connect } from 'react-redux';
import { getClientid } from 'redux/selectors';
import moment from 'moment';
import queryKeys from 'common/queryKey';
import DetailsModal from 'organisms/DetailsModal';

const EmployeesTotalExperience = ({ value, clientid }) => {
  // const [employeesTotalExperienceData, setEmployeesTotalExperienceData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState([]);

  const { getEmployeesTotalExperienceData } = queryKeys;

  const payload = {
    method: "employeesTotalExperience",
    parameters: {
      startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
      endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
      managingOffice: value?.managingoffice || [],
      competencyQC: value?.competencyQC || [],
      officesupervisor: value?.officesupervisor || ""
    }
  };
  
  const payloadString = JSON.stringify(payload);
  const { key, url } = getEmployeesTotalExperienceData(clientid, payloadString);
  const { data, isLoading, refetch } = useFetch(key, url, { enabled: !!clientid }, payload);

  

  const calculateTotalExperience = () => {
    if(data?.response?.employeesTotalExperience){
      return data?.response?.employeesTotalExperience.map(user => {
        let totalExperience = user.currentCompanyExperience;
  
        // if (Array.isArray(user.previousCompanyWorkExperience)) {
        //   user.previousCompanyWorkExperience.forEach(experience => {
        //     const fromDate = new Date(experience.fromdate);
        //     const toDate = new Date(experience.todate);
        //     const duration = (toDate - fromDate) / (1000 * 60 * 60 * 24 * 365); // convert milliseconds to years
        //     totalExperience += duration;
        //   });
        // }
  
        return {
          employeeid: user?.employeeId,
          firstname: user?.firstName,
          lastname: user?.lastName,
          userid: user?.userId,
          totalExperience: Math.floor(totalExperience) // rounded to the nearest whole number
        };
      });
    }
    
  };

 

  const groupByExperience = (data) => {
    const groupedData = Array.from({ length: 51 }, (_, i) => ({ xValue: i, Count: 0 }));
    if(data?.length > 0){
      data.forEach(item => {
        const exp = item.totalExperience;
        if (exp >= 0 && exp <= 50) {
          groupedData[exp].Count++;
        }
      });
    }
    
    return groupedData;
  };

  const totalExperienceData = groupByExperience(calculateTotalExperience());

  const config = {
    data: totalExperienceData,
    xField: 'xValue',
    yField: 'Count',
    color: '#9CD3FA',
    animation: false,
    onReady: (plot) => {
      plot.on('plot:click', (evt) => {
        const { data } = evt;
        if (data && data.data) {
          const tabledata = calculateTotalExperience().filter((item) => item.totalExperience === data.data.xValue);
          setModalContent(tabledata);
          setIsModalVisible(true);
        }
      });
    },
    label: {
      layout: [
        { type: 'interval-adjust-position' }, // Adjust label position to avoid overlap
        { type: 'interval-hide-overlap' }, // Hide overlapping labels
        { type: 'adjust-color' } // Adjust label color based on the background color
      ],
    },
    yAxis: {
      title: {
        text: 'Head Count',
        style: {
          fontSize: 12,
          fontWeight: 500
        },
      },
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      }
    },
    xAxis: {
      title: {
        text: 'Years of Experience',
        style: {
          fontSize: 12,
          fontWeight: 500
        },
      },
      tickInterval: 5,
      grid: {
        line: {
          style: {
            opacity: 0
          }
        }
      }
    },
  };

  return (
    <>
      <h6 className='mt-2'>Employees total experience:</h6>
      <div className='box-container'>
        <Column {...config} />
      </div>
      <DetailsModal
        isopen={isModalVisible}
        isCancel={() => setIsModalVisible(false)}
        tabledata={modalContent}
      />
    </>
  );
}

const mapStateToProps = state => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(EmployeesTotalExperience);
