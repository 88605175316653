import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import './index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

export const client = new QueryClient();


const activePage = window.location.pathname.replace('/', '');


let base64Val = '';
let nameVal = '';
let MIMEtypeVal = '';
function uploadDocumentApp(base64, name, MIMEtype) {
  console.log(base64);
  console.log(name);
  console.log(MIMEtype);
  base64Val = base64;
  nameVal = name;
  MIMEtypeVal = MIMEtype;
}

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={client}>
    <PersistGate loading={null} persistor={persistor}>
      <App activePage={activePage} isApp={window.location.search.indexOf('app') >= 0} documentData={{base64Val, nameVal, MIMEtypeVal}}/>
    </PersistGate>
      {/* only on development the dev Tools will come */}
      {process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools position="bottom-right" />
      )}
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
