import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { Row, Col } from "antd";
import { DEVICE } from "common/Device";
import { DatePicker, Spin } from "antd";
import "antd/es/date-picker/style/css";
import ProductivityManagerToStaffRatio from "../molecules/ProductivityManagerToStaffRatio";
import ProductivityTopAreasAttrition from "../molecules/ProductivityTopAreasAttrition";
import ProductivityBasicSalaryInfo from "../molecules/ProductivityBasicSalaryInfo";
import ProductivityOvertime from "../molecules/ProductivityOvertime";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { getClientid, getUserRoleId } from "redux/selectors";
import { connect } from "react-redux";
import { CALL_API } from "common/API";
import moment from "moment";

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  .top_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .select_field {
    display: flex;
    gap: 10px;
  }
  .selectbox {
    width: 200px;
  }
  @media ${DEVICE.tablet} {
    .select_field {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .selectbox {
      width: 100%;
    }
    .align-right {
      width: 100%;
      margin-top: 10px;
      float: right;
    }
  }
`;

function Productivity({ clientid }) {
  const containerRef = useRef(null);
  const defaultYear = moment().year();
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [topAreasAttritionData, setTopAreasAttritionData] = useState([]);
  const [salaryData, setSalaryData] = useState([]);
  const [overtimeData, setOvertimeData] = useState([]);

  //future need
  const generatePDF = () => {
    const reportElement = containerRef.current;
    const reportHeight = reportElement.clientHeight;
    html2canvas(reportElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new JsPDF("p", "pt", [reportHeight, reportHeight]);
      pdf.addImage(imgData, "PNG", 0, 0, reportHeight, reportHeight);
      pdf.save("Productivity.pdf");
    });
  };

  const getData = async (year) => {
    toggleReportLoader(true);
    const yearString = year.format("YYYY");
    const { overtimeAnalysisData, topAreaAttritionData, salaryData } =
      await CALL_API(`mgview-productivity/${clientid}`, "post", {
        method: "productivity",
        parameters: {
          year: yearString,
        },
      });
    setTopAreasAttritionData(topAreaAttritionData);
    setSalaryData(salaryData);
    setOvertimeData(overtimeAnalysisData);
    toggleReportLoader(false);
  };

  useEffect(() => {
    getData(moment(`${defaultYear}`, "YYYY"));
  }, []);
  return (
    <>
      <StyledTitle className="page-title-head">Productivity</StyledTitle>

      <StyledFilterRow>
        <Row className="top_row">
          <Col ref={containerRef}>
            <DatePicker.YearPicker
              className="selectbox"
              getPopupContainer={(trigger) => trigger.parentNode}
              defaultValue={moment(`${defaultYear}`, "YYYY")}
              clearIcon={null}
              onChange={getData}
            />
          </Col>
        </Row>
      </StyledFilterRow>
      <StyledFilterRow gutter={16} ref={containerRef}>
        {/* <Col md={24} sm={24} xs={24} xl={12}>
          <ProductivityManagerToStaffRatio />
        </Col> */}

        <Col md={24} sm={24} xs={24} xl={12}>
          <Spin spinning={displayReportLoader}>
            <ProductivityTopAreasAttrition data={topAreasAttritionData} />
          </Spin>
        </Col>
        <Col md={24} sm={24} xs={24} xl={12}>
          <Spin spinning={displayReportLoader}>
            <ProductivityOvertime data={overtimeData} />
          </Spin>
        </Col>
        <Col md={24} sm={24} xs={24} xl={24}>
          <Spin spinning={displayReportLoader}>
            <ProductivityBasicSalaryInfo data={salaryData} />
          </Spin>
        </Col>
      </StyledFilterRow>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(Productivity);
