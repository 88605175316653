import React from "react";
import { Column } from "@ant-design/plots";
import styled from "@emotion/styled";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;

  border-radius: 3px;
  padding: 10px;
  margin-bottom: 25px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function EmployeesPerRole({ report }) {
  const config = {
    padding: "auto",
    data: report,
    height: 300,
    xField: "rolename",
    yField: "users",
    seriesField: "rolename",
    label: {
      position: "top",
      // content:''
    },
    columnStyle: {
      cursor: "pointer",
    },
    legend: false,
    xAxis: {
      label: {
        formatter: (value) => value.slice(0, 3).toUpperCase(),
        offset: 10,
        autoHide: false,
        autoRotate: false,
      },
    },
    yAxis: {
      max: 70,
      grid: {
        line: {
          style: {
            opacity: 0,
          },
        },
      },
    },
    theme: {
      colors20: [
        "#3C88C0",
        "#4BA7B3",
        "#5AC39B",
        "#6ADD7F",
        "#A8DD6A",
        "#DDDD6A",
      ],
    },
  };

  return (
    <>
      <StyledTitle>Number Of Employees Per Role</StyledTitle>
      <StyledChartContainer>
        <Column {...config} />
      </StyledChartContainer>
    </>
  );
}

export default EmployeesPerRole;
