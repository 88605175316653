import React, { useEffect, useState, useRef } from 'react';
import { Line  } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, includes, isNaN, map, max, size, unionBy, find, sumBy } from 'lodash';
import { DatePicker, Space } from 'antd';
import { dateInDetail, getKenyanDateTime } from 'utils/Date';
import { CALL_API } from 'common/API';
import moment from 'moment';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
  .header{
    display:flex;
    justify-content: space-between;
  }
`;

const StyledChartContainer = styled.div`
  height:380px;
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .name-title {
    display:flex;
    justify-content:space-between;
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}





function SickLeaveGraph({}) {
  const [ displayReportLoader, toggleReportLoader ] = useState(false);

  // const data = [
  //   {
  //       month:'Jan',
  //       Leave: getRandomNumber(15, 60)
  //   },
  //   {
  //       month:'Feb',
  //       Leave: getRandomNumber(15, 60)
  //   },
  //   {
  //       month:'Mar',
  //       Leave: getRandomNumber(15, 60)
  //   },
  //   {
  //       month:'Apr',
  //       Leave: getRandomNumber(15, 60)
  //   },
  //   {
  //       month:'May',
  //       Leave: getRandomNumber(15, 60)
  //   },
  //   {
  //       month:'Jun',
  //       Leave: getRandomNumber(15, 60)
  //   },
  //   {
  //       month:'Jul',
  //       Leave: getRandomNumber(15, 60)
  //   },
  //   {
  //       month:'Aug',
  //       Leave: getRandomNumber(15, 60)
  //   },
  //   {
  //       month:'Sep',
  //       Leave: getRandomNumber(15, 60)
  //   },
  //   {
  //       month:'Oct',
  //       Leave: getRandomNumber(15, 60)
  //   },
  //   {
  //       month:'Nov',
  //       Leave: getRandomNumber(15, 60)
  //   },
  //   {
  //       month:'Dec',
  //       Leave: getRandomNumber(15, 60)
  //   },
  // ]

  const data = [
    {
      Leave: 20,
      year: 2019,
    },
    {
      Leave: 30,
      year: 2020,
    },
    {
      Leave: 70,
      year: 2021,
    },
    {
      Leave: 80,
      year: 2022,
    },
    {
      Leave: 93,
      year: 2023,
    },
    {
      Leave: 15,
      year: 2024,
    },
  ];

  function resetCountToZero(data) {
    // Create a new array to avoid modifying the original data
    const newData = data.map(item => (
      {
        ...item,
        Leave: (item.month !== 'Jan' && item.month !== 'Feb') ? 0 : item.Leave
       
    } ));
    return newData;
  }

  function resetAllCountToZero(data) {
    // Create a new array to avoid modifying the original data
    const newData = data.map(item => (
      {
        ...item,
        Leave:  0 
       
    } ));
    return newData;
  }
  const [ reportData, setReportData ] = useState(resetCountToZero(data));
  const config = {
    data: data,
    xField: 'year',
    yField: 'Leave',
    height: 340,
    label:false,
    point: {
      size: 5,
      // shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    yAxis: {
        // max: isNaN(max(map(reportData, 'Hours'))) ? 100 : max(map(reportData, 'Hours')) + 15,

    },
    // xAxis: {
    //   label: {
    //     formatter: (value) => value.slice(0, 3),
    //     offset: 10,
    //     autoHide: false,
    //     autoRotate: true,
    //   },
      
    // },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
    animationBegin: 0,
    animationDuration: 2000,
    animationEasing: 'ease-in-out'
  }

  const defaultYear = moment().year();

//   useEffect(() => { 
//     getData(moment(`${defaultYear}`, 'YYYY'));
//   }, []);

  const [yearSelected, setYearSelected] = useState(null)
  const [total, setTotal] = useState(227)
  const containerRef = useRef(null);
 
  // Note : This Code Need in Future

  // const getData = async (year) =>{
  //   const yearString = year.format('YYYY');
  //   setYearSelected(yearString)
  //   toggleReportLoader(true)
  //   const { ovetimeAnlysisData } = await CALL_API('admin-dashboard', 'post', {
  //     method: 'overTimeAnlysis',
  //     parameters: {
  //       year: yearString,
  //     },
  //   });
  //   const mappedData = ovetimeAnlysisData.map(item => ({
  //     month: item.Month.substring(0, 3),
  //     Hours: parseInt(item.hours.split(':')[0])
  //   }));
  //   setReportData(prevData => {
  //     const mergedData = unionBy(prevData, mappedData, 'month');
  //     mergedData.forEach(item => {
  //       const Hours2 = (find(mappedData, { 'month': item.month }) || {}).Hours || 0;
  //       item.Hours = Hours2;
  //     });
  //     return mergedData;
  //   });
  //   setTotal(sumBy(reportData, 'Hours'));
  //   toggleReportLoader(false)

  // }

  const getData = async(year) =>{
    const yearString = year.format('YYYY');
    console.log("yearString -->", yearString)
    if(yearString === "2023"){
      setReportData(data)
    }
    else if(yearString === "2024"){
      setReportData(resetCountToZero(data))
    }
    else {
      setReportData(resetAllCountToZero(data))
    }
    setYearSelected(yearString)
  }

  return (
    <>      
      <StyledTitle>
          <div className='header' ref={containerRef} >
              Sick Leave Analysis
              {/* <DatePicker.YearPicker getPopupContainer={() => containerRef.current}  defaultValue={moment(`${defaultYear}`, 'YYYY')} clearIcon={null} onChange={getData} /> */}
          </div>    
      </StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayReportLoader}>
          <Line 
            {...config}
             />
        </Spin>
      </StyledChartContainer>
    </>
  );
}


export default SickLeaveGraph