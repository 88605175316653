import React, { useEffect, useState } from 'react'
import { Bar } from '@ant-design/plots';
import moment from 'moment';
import queryKeys from 'common/queryKey';
import { useFetch } from 'hooks/useFetch';
import { connect } from 'react-redux';
import { getClientid } from 'redux/selectors';
import { Spin } from 'antd';
import DetailsModal from 'organisms/DetailsModal';

const initialData = [
    {
        "xValue": "01-P5 & P4",
        "name": "Bachelor",
        "listItem": ["P5", "P4"],
        "Count": 0
    },
    {
        "xValue": "02-P1, P2 & P3",
        "name": "Bachelor",
        "listItem": ["P1", "P2", "P3"],
        "Count": 0
    },
    {
        "xValue": "03-C3, C4, M1 & M2",
        "name": "Bachelor",
        "listItem": ["C3", "C4", "M1", "M2"],
        "Count": 0
    },
    {
        "xValue": "04-B1, B2, C1 & C2",
        "name": "Bachelor",
        "listItem": ["B1", "B2", "C1", "C2"],
        "Count": 0
    },
    {
        "xValue": "05-A1 & A2",
        "name": "Bachelor",
        "listItem": ["A1", "A2"],
        "Count": 0
    },
    {
        "xValue": "06-S1,S2,S3 & S4",
        "name": "Bachelor",
        "listItem": ["S1", "S2", "S3", "S4", "S5"],
        "Count": 0,
      },
    {
        "xValue": "01-P5 & P4",
        "name": "Diploma",
        "listItem": ["P5", "P4"],
        "Count": 0
    },
    {
        "xValue": "02-P1, P2 & P3",
        "name": "Diploma",
        "listItem": ["P1", "P2", "P3"],
        "Count": 0
    },
    {
        "xValue": "03-C3, C4, M1 & M2",
        "name": "Diploma",
        "listItem": ["C3", "C4", "M1", "M2"],
        "Count": 0
    },
    {
        "xValue": "04-B1, B2, C1 & C2",
        "listItem": ["B1", "B2", "C1", "C2"],
        "name": "Diploma",
        "Count": 0
    },
    {
        "xValue": "05-A1 & A2",
        "name": "Diploma",
        "listItem": ["A1", "A2"],
        "Count": 0
    },
    {
        "xValue": "06-S1,S2,S3 & S4",
        "name": "Diploma",
        "listItem": ["S1", "S2", "S3", "S4", "S5"],
        "Count": 0,
      },
    {
        "xValue": "01-P5 & P4",
        "name": "Certificate",
        "listItem": ["P5", "P4"],
        "Count": 0
    },
    {
        "xValue": "02-P1, P2 & P3",
        "name": "Certificate",
        "listItem": ["P1", "P2", "P3"],
        "Count": 0
    },
    {
        "xValue": "03-C3, C4, M1 & M2",
        "name": "Certificate",
        "listItem": ["C3", "C4", "M1", "M2"],
        "Count": 0
    },
    {
        "xValue": "04-B1, B2, C1 & C2",
        "name": "Certificate",
        "listItem": ["B1", "B2", "C1", "C2"],
        "Count": 0
    },
    {
        "xValue": "05-A1 & A2",
        "name": "Certificate",
        "listItem": ["A1", "A2"],
        "Count": 0
    },
    {
        "xValue": "06-S1,S2,S3 & S4",
        "name": "Certificate",
        "listItem": ["S1", "S2", "S3", "S4", "S5"],
        "Count": 0,
      },
    
    {
        "xValue": "01-P5 & P4",
        "name": "Master",
        "listItem": ["P5", "P4"],
        "Count": 0
    },
    {
        "xValue": "02-P1, P2 & P3",
        "listItem": ["P1", "P2", "P3"],
        "name": "Master",
        "Count": 0
    },
    {
        "xValue": "03-C3, C4, M1 & M2",
        "name": "Master",
        "listItem": ["C3", "C4", "M1", "M2"],
        "Count": 0
    },
    {
        "xValue": "04-B1, B2, C1 & C2",
        "name": "Master",
        "listItem": ["B1", "B2", "C1", "C2"],
        "Count": 0
    },
    {
        "xValue": "05-A1 & A2",
        "name": "Master",
        "listItem": ["A1", "A2"],
        "Count": 0
    },
    {
        "xValue": "06-S1,S2,S3 & S4",
        "name": "Master",
        "listItem": ["S1", "S2", "S3", "S4", "S5"],
        "Count": 0,
      },
    {
        "xValue": "01-P5 & P4",
        "name": "Doctorate",
        "listItem": ["P5", "P4"],
        "Count": 0
    },
    {
        "xValue": "02-P1, P2 & P3",
        "listItem": ["P1", "P2", "P3"],
        "name": "Doctorate",
        "Count": 0
    },
    {
        "xValue": "03-C3, C4, M1 & M2",
        "name": "Doctorate",
        "listItem": ["C3", "C4", "M1", "M2"],
        "Count": 0
    },
    {
        "xValue": "04-B1, B2, C1 & C2",
        "name": "Doctorate",
        "listItem": ["B1", "B2", "C1", "C2"],
        "Count": 0
    },
    {
        "xValue": "05-A1 & A2",
        "name": "Doctorate",
        "listItem": ["A1", "A2"],
        "Count": 0
    },
    {
        "xValue": "06-S1,S2,S3 & S4",
        "name": "Doctorate",
        "listItem": ["S1", "S2", "S3", "S4", "S5"],
        "Count": 0,
      },
]

function QualificationByGradecode({ value, clientid }) {

    const [dataList, setDataList] = useState(initialData);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [plotData, setPlotData] = useState("");


    const { getQualificationByGradeCode } = queryKeys;

    const payload = {
        method: "qualificationByGradeCode",
        parameters: {
            startDate: value?.date ? moment(value?.date).format("YYYY-MM-DD") : "",
            endDate: value?.end_date ? moment(value?.end_date).format("YYYY-MM-DD") : "",
            managingOffice: value?.managingoffice || [],
            competencyQC: value?.competencyQC || [],
            officesupervisor: value?.officesupervisor || ""
          }
    };

    const payloadString = JSON.stringify(payload);
    const { key, url } = getQualificationByGradeCode(clientid, payloadString);

    const { data, isLoading, refetch } = useFetch(
        key,
        url,
        {
            enabled: !!clientid,
        },
        payload
    );

    useEffect(() => {
        const response = data?.response?.qualificationByGradeCode;
        if (response) {
            const educationMap = initialData.reduce((acc, item) => {
                acc[item.xValue + item.name] = { ...item, Count: 0 };
                return acc;
            }, {});
            if(response.length > 0){
                response.forEach(user => {
                    if(user?.educationdetails?.length > 0){
                        user.educationdetails.forEach(ed => {
                            const grade = user.grade;
                            const awarded = ed.awarded;
    
                            initialData.forEach(item => {
                                if (item.listItem.includes(grade) && item.name === awarded) {
                                    educationMap[item.xValue + item.name].Count += 1;
                                }
                            });
                        });
                    }
                    
                });
            }
           

            const result = Object.values(educationMap);
            setDataList(result);
        }
    }, [data]);

    const plotClick = () =>{
        if(plotData?.data?.data){
            const filterData = data?.response?.qualificationByGradeCode?.filter(
                (item) => {
                  return (
                    item.educationdetails.some(
                      (ed) => ed.awarded === plotData.data.data.name
                    ) && plotData.data.data.listItem?.includes(item?.grade)
                  );
                }
              );
    
              setTableData(filterData);
              setIsModalVisible(true);
        } 
}

useEffect(()=>{
    if(plotData !== ""){
    plotClick();
    }
},[plotData])

    const config = {
        data: dataList,
        animation: false,
        isStack: true,
        xField: 'Count',
        yField: 'xValue',
        seriesField: 'name', // Group by the 'name' field
        colorField: 'name',
        groupField: 'name', // Group by the 'name' field
        label: false,
        color: ['#D6A5FF', '#FAC49C', '#C2FAE5', '#F26B6B', '#9CD3FA'],
        onReady: (plot) => {
            plot.on("plot:click", (evt) => {
              setPlotData(evt);
            });
          },
        xAxis: {
            tickInterval: 4,
            grid: {
                line: {
                    style: {
                        opacity: 0
                    }
                }
            }
        },
    };
    return (
        <>
        <h6 className="mt-2">Qualification By Grade code:</h6>
        <div className='box-container'>
            <Spin spinning={isLoading}>
                <Bar {...config} />
            </Spin>
        </div>
        <DetailsModal
        isopen={isModalVisible}
        isCancel={() => setIsModalVisible(false)}
        tabledata={tableData}
      />
        </>
        
    )
}

const mapStateToProps = (state) => ({
    clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(QualificationByGradecode);
